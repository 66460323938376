.footer-container{
    background-color: #15171a;
    padding: 3rem 0;
    display: flex;
    position: relative;
}
.footer-info{
    width: 25%;
    color: #fff;
    text-align: justify;
    padding: 1rem;
}
.footer-info img{
    width: 30px;
    margin-bottom: 1rem;
}
.social-icons{
    display: flex;
    justify-content: start;
}
.icon{
    background-color: #333333;
    padding: 0.3rem 0.8rem;
    border-radius: 50%;
    margin: 0.2rem;
    transition: 0.5s ease;
}
.icon:hover{
    background-color:#1b2f5b !important;
    cursor: pointer !important;
}
.icon a{
    color: #fff;
}
.icon a:hover{
    color: #fff;
}
.footer-quick-links{
    width: 25%;
    margin-left: 7rem;
}
h4{
    color: #fff;
    position: relative;
}
h4::before{
    content: '';
    position: absolute;
    left: 0;
    bottom: -0.5rem;
    width: 40px;
    height: 2px;
    background-color: #1b2f5b;
}
.quick-links,
.footer-product-links{
    margin-top: 2rem;
}
.quick-links ul li,
.footer-product-links ul li{
    list-style: none;
    margin-top:1rem ;
    margin-left: -2rem;
}
.quick-links ul li a{
    color: #fff;
    text-decoration: none;
    transition: 0.5s ease;
}
.footer-product{
    margin-left: 6rem;
    z-index: 10;
}
.footer-product-links ul li a{
    color: #fff !important;
    text-decoration: none;
    transition: 0.5s ease;
    
    
}
.quick-links ul li a:hover{
    color: tomato;
    cursor: pointer;
}
.footer-product-links ul li .foo-pro-links:hover{
    color: tomato !important; 
}
.circle-map {
    position: absolute;
    top: 20%;
    right: 18%;
    z-index: 2;
}
.circle-map img {
    max-width: 100%;
    height: auto;
    z-index: 0;
}
.circle-map img{
    vertical-align: middle;
    border-style: none;
}
.lines {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    margin: auto;
    width: 90vw;
    z-index: 3;
}
.lines .line {
    position: absolute;
    width: 1px;
    height: 100%;
    top: 0;
    left: 50%;
    background: rgba(255, 255, 255, 0.1);
    overflow: hidden;
}
.lines .line::after {
    content: "";
    display: block;
    position: absolute;
    height: 15vh;
    width: 100%;
    top: -50%;
    left: 0;
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0)), color-stop(75%, #ffffff), to(#ffffff));
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #ffffff 75%, #ffffff 100%);
    -webkit-animation: run 7s 0s infinite;
    animation: run 7s 0s infinite;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
    animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
}
@keyframes run {
    0% {
      transform: translateY(0vh);
    }
    75% {
      transform: translateY(130vh);
    }
    100% {
      transform: translateY(130vh);
    }
  }
.lines .line:nth-child(1) {
    margin-left:-16rem;
}
.lines .line:nth-child(1)::after {
    -webkit-animation-delay: 2.5s;
    animation-delay: 2.5s;
}
.lines .line:nth-child(3) {
    margin-left: 25%;
}
.lines .line:nth-child(3)::after {
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
}
.copyright-container{
    background-color: #000000;
    padding: 1.2rem 5rem;
}
.copyright-container p{
    color: #fff;
}
.copyright-container p a{
    text-decoration: none;
    color: #fff;
    transition: 0.5s;
}
.copyright-container p a:hover{
    color: tomato;
}
.go-top {
    position: fixed;
    cursor: pointer;
    top: 0;
    right: 2%;
    color: #ffffff;
    z-index: 4;
    width: 40px;
    text-align: center;
    height: 42px;
    line-height: 42px;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: .9s;
    transition: .9s;
    background-color: #1b2f5b;
  }
  
  .go-top.active {
    top: 98%;
    -webkit-transform: translateY(-98%);
            transform: translateY(-98%);
    opacity: 1;
    visibility: visible;
  }
  
  .go-top i {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: 0 auto;
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }
  
  .go-top i:last-child {
    opacity: 0;
    visibility: hidden;
    top: 60%;
  }
  
  .go-top::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    background-color: #ff4800;
  }
  
  .go-top:hover, .go-top:focus {
    color: #ffffff;
  }
  
  .go-top:hover::before, .go-top:focus::before {
    opacity: 1;
    visibility: visible;
  }
  
  .go-top:hover i:first-child, .go-top:focus i:first-child {
    opacity: 0;
    top: 0;
    visibility: hidden;
  }
  
  .go-top:hover i:last-child, .go-top:focus i:last-child {
    opacity: 1;
    visibility: visible;
    top: 50%;
  }
  .fa-arrow-up:before {
    content: "\f062";
}
  
  @media only screen and (max-width:1025px){
    .footer-container{
        padding: 0rem;
        flex-direction: column;
    }
    .footer-info{
        width: 60%;
    }
    .footer-heading h4{
        margin-left:1rem ;
    }
    .footer-quick-links{
        width: 100%;
      margin: 1rem 0;
    }
    .footer-product-links{
        width: 100%;
      margin: 1rem 0;
    }
    .footer-product{
        width: 100%;
        margin: 1rem 0;
        padding: 1rem;
        
    }
    .quick-links ul li,
    .footer-product-links ul li{
        list-style: none;
        margin-top:0.5rem ;
        margin-left: 0rem;
        margin-right: 0;
    }
    .copyright-container{
        padding: 0.8rem  0;
        text-align: center;
    }
    .copyright-container p{
        font-size: 0.8rem;
    }
    .go-top {
        top: 0;
        right: 10%;
        width: 30px;
        height: 30px;
      }
      .circle-map {
        position: absolute;
        top: 0%;
        right: 0%;
        z-index: 2;
    }
  }
  