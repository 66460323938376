@import "";

.video{
    display: flex;
    margin-top: 6rem;
    position: relative;
}
.video-text-container{
    width: 50%;
    padding: 8rem 5rem;
    padding-left: 0;
}
.video-text-container h1{
    width: 100%;
    font-size: 2.5rem;
    font-weight: 600;
}
.video-text-container p{
    text-align: justify;
    margin-top: 1.2rem;
}
#startergy{
    color: tomato;
}
.video-img-container{
    width: 50%;
}
.video-img{
    height: 500px;
    width: 100%;
}
.play{
    position: absolute;
    height: 100px;
    width: 100px;
    left:50%;
    top: 50%;
    transform: translate(-50%,-50%);
    z-index: 1;
    background-color: #1b2f5b;
    color: #fff;
    padding: 2rem;
    border-radius: 50%;
    transition: 0.5s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.play .fa-play{
    font-size: 2.5rem;
    text-align: center;
}
.play:hover{
    background-color: tomato;
    color: #fff;
}
.play a{
    color: #fff;
    text-decoration: none;
}
.play::before,
.play::after{
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    bottom: 0;
    left: 0;
    border-radius: 50%;
    border: 1px solid #1b2f5b;
}
.play a:hover{
    color: #fff;
}
.play::before{
    -webkit-animation: ripple 1s linear 1s infinite;
          animation: ripple 1s linear 1s infinite;
}
.play::after{
    -webkit-animation: ripple 1s linear 1s infinite;
          animation: ripple 1s linear 1s infinite;
}

@keyframes ripple {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    75% {
      -webkit-transform: scale(1.75);
              transform: scale(1.75);
      opacity: 0.7;
    }
    100% {
      -webkit-transform: scale(2);
              transform: scale(2);
      opacity: 0;
    }
  }
  


@keyframes modal-video {
    from {
        opacity: 0;
   }
    to {
        opacity: 1;
   }
}
@keyframes modal-video-inner {
    from {
        transform: translate(0, 100px);
   }
    to {
        transform: translate(0, 0);
   }
}
.modal-video {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    z-index: 1000000;
    cursor: pointer;
    opacity: 1;
    animation-timing-function: ease-out;
    animation-duration: 0.3s;
    animation-name: modal-video;
    -webkit-transition: opacity 0.3s ease-out;
    -moz-transition: opacity 0.3s ease-out;
    -ms-transition: opacity 0.3s ease-out;
    -o-transition: opacity 0.3s ease-out;
    transition: opacity 0.3s ease-out;
}
.modal-video-effect-exit {
    opacity: 0;
}
.modal-video-effect-exit .modal-video-movie-wrap {
    -webkit-transform: translate(0, 100px);
    -moz-transform: translate(0, 100px);
    -ms-transform: translate(0, 100px);
    -o-transform: translate(0, 100px);
    transform: translate(0, 100px);
}
.modal-video-body {
    max-width: 960px;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    padding: 0 10px;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
}
.modal-video-inner {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}
@media (orientation: landscape) {
    .modal-video-inner {
        padding: 10px 60px;
        box-sizing: border-box;
   }
}
.modal-video-movie-wrap {
    width: 100%;
    height: 0;
    position: relative;
    padding-bottom: 56.25%;
    background-color: #333;
    animation-timing-function: ease-out;
    animation-duration: 0.3s;
    animation-name: modal-video-inner;
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    -webkit-transition: -webkit-transform 0.3s ease-out;
    -moz-transition: -moz-transform 0.3s ease-out;
    -ms-transition: -ms-transform 0.3s ease-out;
    -o-transition: -o-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
}
.modal-video-movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.modal-video-close-btn {
    position: absolute;
    z-index: 2;
    top: -45px;
    right: 0px;
    display: inline-block;
    width: 35px;
    height: 35px;
    overflow: hidden;
    border: none;
    background: transparent;
}
@media (orientation: landscape) {
    .modal-video-close-btn {
        top: 0;
        right: -45px;
   }
}
.modal-video-close-btn:before {
    transform: rotate(45deg);
}
.modal-video-close-btn:after {
    transform: rotate(-45deg);
}
.modal-video-close-btn:before, .modal-video-close-btn:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px;
}



  @media only screen and (max-width:1025px){
      .video{
          flex-direction: column;
          position: relative;
      }
      .video-text-container{
        width: 100%;
        padding: 1rem;
        margin: 0 auto;
    }
    .video-text-container h1{
        width: 100%;
        font-size: 1.6rem;
    }
    .video-text-container p{
        font-size: 0.9rem;
    }
    #startergy{
        font-size: 1rem;
    }
    .video-img-container{
        width: 100%;
    }
    .video-img{
        width: 100%;
        height: auto;
    }
    .play{
        height: 50px;
        width: 50px;
        left:50%;
        top: 50%;
        transform: translate(-50%,200%);
        padding: 1rem;
    }
    .play .fa-play{
        font-size: 1.2rem;
    }
  }
