*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}
.header-container{
    background-color: #1b2f5b;
    color: #fff;
    padding: 0.3rem;
    display: flex;
    top: 0;
    width: 100%;
    z-index: 1;
    justify-content: space-between;
}
.header-container .container{
    display: flex;
    justify-content: space-between;
    z-index: 100;
}
.header-left-content{
    margin-top: 0.5rem;
    margin-left: 0rem;
}
.header-right-content{
    margin-top: 0.5rem;
    margin-right: 0rem;
}
.header-right-content ul {
    display: flex;
}
.header-right-content ul li{
    list-style-type: none;
    margin-left: 1.2rem;
}
.header-right-content ul li a{
    text-decoration: none;
    color: #fff;
}
.header-right-content ul li a .fa{
    margin-right: 0.3rem;
}

@media only screen and (max-width:1025px){
    .header-container{
        display: none;
    }
}