.quotes-img{
    width: 100%;
    height: 500px;
    background-size: cover;
}
.quotes-para{
    text-align: left;
    font-size: 1.3rem;
}
.fa-quote-left{
    font-size: 4rem;
    position: absolute;
    left: 0;
    top: -3rem;
    opacity: 0.5;
}
.quotes-content h5{
    font-weight: 700;
    text-align: left;
}
.quotes-content{
    position: absolute;
    width: 40%;
    top: 11rem;
}
.quotes-container{
    position: relative;
    width: 100%;
    height: auto;
}
.quotes-container .carousel-fade .carousel-item{
    width: 100%;
    height: 600px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}
.quotes-container .carousel-fade .carousel-item img{
    display: none;
}
.carousel-item::before{
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    left: -30%;
    z-index: 99;
    background-position: left;
    background-size: contain;
    background-repeat: no-repeat;
}

.coo-para{
    text-align: left;
}
@media only screen and (max-width:1025px){
    .quotes-content{
        width: 100%;
        position: relative;
        margin: 0 auto;
        padding:0rem;
        top: 2rem;
    }
    .quotes-container .carousel-fade .carousel-item{
        width: 100%;
        height: 450px;
    }
    .quotes-container .carousel-fade .carousel-caption {
        position: absolute;
        top: 50%;
        bottom: unset;
        left: 50% !important;
        padding-top: 0;
        padding-bottom: 0;
        transform: translate(-50%,-50%);
        color: #fff;
        text-align: center;
        width: 80%;
    }
    .quotes-para{
        position: relative;
        text-align: left;
        font-size: 0.8rem;
        margin: 0;
        padding:0rem;
        text-align: justify;
    }
    .quotes-container{
        background-color: #1b2f5b;
        padding: 0rem ;
        margin: 0 auto;
    }
    .fa-quote-left{
        font-size: 1.2rem;
        top: -1rem;
    }
    .quotes-img{
        position: relative;
        height: 70px;
        width: 120px;
        border-radius: 10px;
        text-align: left;
        margin: 15rem 0 0 3rem;
    }
    .quotes-content h5{
        margin-top: 1rem;
        font-size: 1rem;
    }
    
}
