.eco-container{
    margin-top: 3rem;
}
.eco-heading{
    text-align: center;
}
.eco-heading h1{
    font-size: 2.7rem;
}
.eco-image-heading{
    display: flex;
    width: cal(250px * 10);
    margin: 2rem auto;
    animation: scroll 20s linear infinite;
}
.eco-image-heading:hover{
    animation-play-state: paused;
}
@keyframes scroll{
    0%{
        transform:translateX(0px);
    }
    100%{
        transform:translateX(calc(-250px * 5));
    }
}
.eco-image{
    margin: 0 1rem;
    height: 200px;
    width: 250px;
    padding: 15px;
    display: flex;
    align-items: center;
    perspective: 100px;
}
.eco-image img{
    width: 100%;
    transition: transform 0.6s;
}
img:hover{
    transform: translateZ(10px);
}
.slide-container{
    height: 250px;
    position: relative;
    overflow: hidden;
    width: 100%;
    margin: auto;
    display: grid;
    place-items: center;
    
}
@media only screen and (max-width:1025px){
    .eco-heading h1{
        font-size: 1.5rem;
    }
    .eco-heading p{
        font-size: 0.8rem;
    }
    .eco-container{
        margin: 3rem auto;
        width: 100%;
    }
    .slide-container{
        width: 90%;
        margin: 0 auto;
    }
    .eco-image{
        height: 150px;
        width: 200px;
    }
    .eco-image-heading{
        width: cal(100px * 10);
        margin: 2rem auto;
        animation: scroll 20s linear infinite;
    }
}