.contact-container{
    position: relative;
}
.contact-banner-area{
    margin-top: -6.5rem;
    position: relative;
    z-index: 1;
    padding-top: 210px;
    padding-bottom: 140px;
    background-color: #000000;
    background-position: center center !important;
    background-size: cover !important;
    background-repeat: no-repeat !important; 
} 
.contact-banner-area::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: #000000;
    opacity: .60;
}
.contact-links{
    margin: 0;
}
.contact-links ul{
    display: flex;
    margin-left: -2rem;
}
.contact-links ul li{
    list-style-type: none;
    font-weight: 600;
    color: #fff;
    margin-right: 0.5rem;
}
.home-link{
    text-decoration: none;
    color: tomato;
}
.home-link:hover{
    color: tomato;
    cursor: pointer;
}
.contact-h2{
    color: #fff;
    font-weight: 600;
    font-size: 2.7rem;
}
.lines {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    margin: auto;
    width: 90vw;
    z-index: -1;
}
.lines .line:nth-child(1) {
    margin-left: -25%;
}
.lines .line {
    position: absolute;
    width: 1px;
    height: 100%;
    top: 0;
    left: 50%;
    background: rgba(255, 255, 255, 0.1);
    overflow: hidden;
}
.lines .line:nth-child(1)::after {
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
}
.lines .line::after {
    content: "";
    display: block;
    position: absolute;
    height: 15vh;
    width: 100%;
    top: -50%;
    left: 0;
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0)), color-stop(75%, #ffffff), to(#ffffff));
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #ffffff 75%, #ffffff 100%);
    -webkit-animation: run 7s 0s infinite;
    animation: run 7s 0s infinite;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
    animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
}
.lines .line:nth-child(3) {
    margin-left: 25%;
}
.lines .line:nth-child(3)::after {
    -webkit-animation-delay: 2.5s;
    animation-delay: 2.5s;
}
.contact-heading-container{
    text-align: center;
}
.contact-para{
    line-height: 0.1;
    font-weight: 600;
}
.contact-heading{
    font-size: 2.7rem;
}
.contact-form-container{
    padding:6rem;
}
.contact-form-content{
    display: flex;
    justify-content: center;
    padding: 2rem 0;
}
.contact-form-img{
    width: 50%;
    margin-right: 3rem;
}
.contact-image{
    width: 75%;
}
.contact-form{
    width: 50%;
    margin-left: 3rem;
    margin-top: 2.5rem;
}
.input-container input,textarea{
    border: none;
    padding: 1rem;
    margin-bottom: 1rem;
    font-weight: 400;
    opacity: 0.8;
}
.input-container textarea{
    border: none;
    padding: 1rem 3rem;
    margin-bottom: 1rem;
    font-weight: 400;
    opacity: 0.8;
}
.input-container input:active{
    border: none;
}
input[placeholder]{
    color: #333;
}
.send-btn{
    background-color:#1b2f5b;
    padding: 0.8rem 2.2rem;
    border-radius: 25px;
    color:#fff;
    transition: 0.5s ease;
    border: none;
    margin-left: 10rem;
    font-weight: 600;
    font-size: 1.1rem;
}
.send-btn:hover{
    background-color: tomato;
}
.contact-details-container{
    text-align: center;
    margin: 2rem;
}
.phone-number{
    color: #1b2f5b;
    text-decoration: none;
    font-size: 1.6rem;
    font-weight: 600;
    transition: 0.5s;
}
.phone-number:hover{
    color: tomato;
}
.or{
    color: #333;
    font-weight: 400;
    font-size: 1.2rem;
}
.contact-email{
    color: tomato;
    text-decoration: none;
    font-size: 1.6rem;
    transition: 0.5s;
}
.contact-email:hover{
    color: #1b2f5b;
}
.contact-icons{
    display: flex;
    justify-content: center;
    margin-top: 1rem;
}
.contact-icon{
    background-color: #fff;
    padding: 0.3rem 0.8rem;
    border-radius: 50%;
    margin: 0.2rem;
    transition: 0.5s ease;
    border: 1px solid rgb(163, 162, 162);
    opacity: 0.4;
}
.contact-icon a{
    color:#333 ;
}
.contact-icon a:hover{
    text-decoration: none;
    color: #fff;
}
.contact-icon:hover{
    background-color:#1b2f5b !important;
    cursor: pointer !important;
    color: #fff;
    opacity: 1;
}
.contact-icon:hover a{
    color: #fff;
}
/* ther is email checker thing  */

.display_message{
    display: block;
}
.notdisplay_message{
    display:none;
}
.display-container{
    width: 50%;
    margin:2rem 0;
    text-align: center;
    color:#1b2f5b ;
    font-weight: 600;
    font-size: 1.5rem;
}
#display-success{
    color: green;
}
#display-error{
    color: red;
}


.recaptcha{
    width: 50%;
    margin: 1rem 0 0 4rem;
    padding: 2rem;
}
.captcha-error{
    width: 50%;
    color: red;
    font-size: 1.3rem;
    margin: 1rem 0 2rem 10rem;
}

@media only screen and (max-width:1025px){

.recaptcha{
    width: 100%;
    margin: 0;
}
.captcha-error{
    width: 100%;
    text-align: center;
    margin: 1rem 0;
}
    .display-container{
        width: 100%;

        font-size: 1.2rem;
    }
    .contact-links{
        margin: 0;
        text-align: center;
    }
    .contact-links ul{
       justify-content: center;
    }
    .contact-form-content{
        flex-direction: column;
        padding: 0rem;
    }
    .input-container{
        flex-direction: column !important;
        padding: 0;
        width: 100%;
    }
    .contact-heading{
        font-size: 1.7rem;
    }
    .contact-form-container{
        padding:2rem 0;
        margin: 2rem 0;
        width: 100%;
        margin: auto;
    }
    .contact-form-img{
        margin: 0 auto;
        width: 90%;
    }
    .contact-image{
        width: 100%;
        height: auto;
    }
    .contact-form{
        text-align: left;
        margin: 2rem auto;
        width: 100%;
    }
    .send-message-btn{
        width: 80%;
        margin: 0 auto;
        text-align: center;
    }
    .send-btn{
       padding: 0.7rem 2.5rem;
       margin: 0 auto;
    }
    .input-container input,textarea{
        padding: 1rem 1.1rem;
        outline: none;
    }
    .input-container textarea{
        padding: 1rem 1.1rem;
    }
}
