
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&family=Roboto:wght@500&display=swap');

.navbarMenu{
    position: sticky;
    max-width: 100%;
    height:auto;
    top: 0;
    align-items: center;
    z-index: 10; 
}
.navbarContainer{
    display: flex;
    justify-content: space-between;
    background: transparent;
    padding: 1rem;
}
.navbarMenu.active{
    background-color: #fff;
    color: #1b2f5b;
    z-index: 10;
}
.logo-container img {
    object-fit: contain;
    height: 60px;
}
.navbar-list {
    display: flex;
    align-items: center;
    max-width: 100%;
    list-style: none;
}
.navbar-list ul{
    display: flex;
    margin-bottom: 0;
}
.navbar-list ul li{
    list-style: none;
    margin-left: 1rem;
 
}
.navbar-list ul li a{
    text-decoration: none;
    color:#fff;
    font-weight: 600;
}
.navbar-list.active ul li a{
    color:#1b2f5b;
}
.navbar-list ul li a:hover{
    color: tomato;
}
.link.active-link{
    color: tomato;
}
.product.active-link{
    color: tomato;
}
.product{
    position: relative;
    font-weight: 600;
    color: #fff;
   
}
.product.active{
    color: #1b2f5b;
}

.product-links{
    position: absolute;
    width: 150px;
    height: 100px;
    display: none;
    top: 0rem;
    left: 0rem;
    background: #fff;
    padding: 0.5rem;
    text-align: left;
    margin-top: 1.5rem;
    align-items: center;
    z-index: 1;
}
.product:hover .product-links{
    display: block;
    opacity: 1;
}
.pro-link{
    margin: 0.8rem 0;
}
.product:hover{
    position: relative;
    color: tomato;
    cursor: pointer;
}
.product-link{
    text-decoration: none;
    margin:1rem;
    color: #1b2f5b !important;
}
.product-link:hover{
    color: tomato !important;
}
.connect-us{
    background-color:#fff;
    padding: 0.6rem 2rem;
    border-radius: 20px;
    margin-top: -0.5rem;
    color:#1b2f5b !important;
    transition: 0.5s ease;
}
.connect-us.active{
    background-color:#1b2f5b;
    color: #fff !important;
}
.connect-us:hover{
    background-color:#1b2f5b;
    color: #fff !important;
}
.connect-us.active:hover{
    background-color: tomato;
    color: #fff;
}

.openMenu {
    font-size: 1.7rem;
    display: none;
    cursor: pointer;
    color: #fff;
}
.openMenu.active{
    color: #000;
}
.closeMenu{
    font-size: 2rem;
    display: none;
    cursor: pointer;
    color: #000;
}

@media only screen and (max-width:1025px){
    .navbarMenu{
        background-color: #000;
        /* padding: 2rem; */
        justify-content: space-between;
        height: auto;
    }
    .logo-container img {
        height: 35px;
        margin-left: 0rem;
    }
    .navbar-list ul{
        flex-direction: column;
    }
    .navbar-list ul li{
        margin: 1rem;
        font-weight: 500;
    }
    .navbar-list ul li a{
        color: #677294;
    }
    .product{
        color: #677294;
    }
    .connect-us{
        display: none;
    }
    .navbar-list {
        height: 60vh;
        max-width: 100%;
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        z-index: 10;
        flex-direction: column;
        justify-content: center;
        align-items:flex-start;
        background:#fff;
        transition: top 1s ease;
        display: none;
    }
    .closeMenu {
        display: block;
        position: absolute;
        top: 3%;
        right: 12%;
    }
    .openMenu {
        display: block;
    }
    .product-links{
        position: absolute;
        width: 100px;
        height: 100px;
        display: none;
        top: -2rem;
        left: 100%;
        background: #fff;
        padding: 0.5rem;
        text-align: left;
        margin-top: 1.5rem;
        align-items: center;
        z-index: 1;
    }
    .product-link{
        font-size: 0.9rem;
        color: #677294 !important;
    }
}