.article-container{
    text-align: left;
    margin-top: 3rem;
    padding: 3rem 0;
}
.article-container h1{
    text-align: center;
}
#head-para{
    text-align: center;
}
.article-container p{
    text-align: left;
}
.article-container h1{
    font-size: 3rem;
}
.article-content{
    display: flex;
    justify-content:space-between;
}
.article-img{
    width: 100%;
    height: auto;
}
.content-container{
    width: 100%;
    height: 330px;
    background-color:#f7f7f7;
    padding: 1rem 2rem;
}
.article-para{
    text-align: left;
}
.article-link{
    text-decoration: none;
    color: black;
    font-size: 1.3rem;
    font-weight: 600;
    margin-bottom: 1rem;
    text-align: justify;
}
.article-link:hover{
    color: black;
}
.read-btn{
    text-align: left;
    position: relative;
    text-decoration: none;
    color:#333333;
}
.read-btn::before {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 1px;
    bottom: -2px;
    left: 0;
    background-color: #000;
    transform: scaleX(0);
    transition: transform 0.3s ease;
  }
  .read-btn:hover::before {
    transform: scaleX(1);
  }
.read-btn:hover{
    color: #333;
}
#aeroplane-container,
#globe-container,
#pricing-container{
    width: 30%;
    transition: 0.6s ease;
}
/* .aeroplane-container:hover,
.globe-container:hover,
.pricing-container:hover{
    margin: 0 1.2rem;
    width: 100%;
    transform: scale(1.02);
    overflow: hidden;
} */
@media only screen and (max-width:1025px){
    .article-content{
        flex-direction: column;
        padding: 1rem!important;
        margin: 1rem auto !important;
    }
    .article-container{
        margin: 1rem auto !important;
    }
    #industry-head{
        font-size: 1.4rem;
    }
    #head-para{
        font-size: 0.8rem;
    }
    .article-content .article-img{
        width: 100% !important;
        height: auto;
        margin: 0rem auto;
    }
    .article-link{
        font-size: 15px !important;
    }
    .aeroplane-container,
    .globe-container,
    .pricing-container{
    margin: 1rem auto!important;
    width: 100% !important;
    }
    .content-container{
        width: 100% !important;
        height: auto;
        margin: 0 auto !important;
        padding: 1rem !important;
    }
}