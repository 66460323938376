.upgrade-container{
    position: relative;
}
.upgrade-banner-area{
    margin-top: -6.5rem;
    position: relative;
    z-index: 1;
    padding-top: 210px;
    padding-bottom: 140px;
    background-color: #000000;
    background-position: center right !important;
    background-size:cover !important;
    background-repeat: no-repeat !important;
} 
.upgrade-banner-area::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: #000000;
    opacity: .60;
}
.upgrade-links{
    /* margin: -2rem 0 0 10rem; */
}
.upgrade-links ul{
    display: flex;
    margin-left: -2rem;
}
.upgrade-links ul li{
    list-style-type: none;
    font-weight: 600;
    color: #fff;
    margin-right: 0.5rem;
}
.home-link{
    text-decoration: none;
    color: tomato;
}
.home-link:hover{
    color: tomato;
    cursor: pointer;
}
.upgrade-h2{
    color: #fff;
    font-weight: 600;
    font-size: 2.7rem;
}
.lines {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    margin: auto;
    width: 90vw;
    z-index: -1;
}
.lines .line:nth-child(1) {
    margin-left: -25%;
}
.lines .line {
    position: absolute;
    width: 1px;
    height: 100%;
    top: 0;
    left: 50%;
    background: rgba(255, 255, 255, 0.1);
    overflow: hidden;
}
.lines .line:nth-child(1)::after {
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
}
.lines .line::after {
    content: "";
    display: block;
    position: absolute;
    height: 15vh;
    width: 100%;
    top: -50%;
    left: 0;
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0)), color-stop(75%, #ffffff), to(#ffffff));
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #ffffff 75%, #ffffff 100%);
    -webkit-animation: run 7s 0s infinite;
    animation: run 7s 0s infinite;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
    animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
}
.lines .line:nth-child(3) {
    margin-left: 25%;
}
.lines .line:nth-child(3)::after {
    -webkit-animation-delay: 2.5s;
    animation-delay: 2.5s;
}
.upgrade-content{
    padding: 6rem;
}
.upgrade-heading{
    display: flex;
    margin-bottom: 3rem;
}
.upgrade-heading .fa{
    font-size: 1.8rem;
    color: #fff;
    background-color: #282560;
    padding: 1rem;
    border-radius: 10px;
}
.upgrade-heading .upgrade-title{
    margin-left: 1rem;
    margin-top: 0.6rem;
    color: #282560;
}
.upgrade-para{
    text-align: justify;
}
@media only screen and (max-width:1025px){
    .upgrade-links{
        margin: 0;
        text-align: center;
    }
    .upgrade-links ul{
        justify-content: center;
        margin-left: -2rem;

    }
    .upgrade-content{
        padding: 3rem 1rem;
    }
}