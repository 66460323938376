.news-container{
    position: relative;
}
.news-banner-area{
    margin-top: -6.5rem;
    position: relative;
    z-index: 1;
    padding-top: 210px;
    padding-bottom: 140px;
    background-color: #000000;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat; 
} 
.news-banner-area::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: #000000;
    opacity: .60;
}
.news-links{
    margin: 0;
}
.news-links ul{
    display: flex;
    margin-left: -2rem;
}
.news-links ul li{
    list-style-type: none;
    font-weight: 600;
    color: #fff;
    margin-right: 0.5rem;
}
.home-link{
    text-decoration: none;
    color: tomato;
}
.home-link:hover{
    color: tomato;
    cursor: pointer;
}
.news-h2{
    color: #fff;
    font-weight: 600;
    font-size: 2.7rem;
}
.lines {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    margin: auto;
    width: 90vw;
    z-index: -1;
}
.lines .line:nth-child(1) {
    margin-left: -25%;
}
.lines .line {
    position: absolute;
    width: 1px;
    height: 100%;
    top: 0;
    left: 50%;
    background: rgba(255, 255, 255, 0.1);
    overflow: hidden;
}
.lines .line:nth-child(1)::after {
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
}
.lines .line::after {
    content: "";
    display: block;
    position: absolute;
    height: 15vh;
    width: 100%;
    top: -50%;
    left: 0;
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0)), color-stop(75%, #ffffff), to(#ffffff));
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #ffffff 75%, #ffffff 100%);
    -webkit-animation: run 7s 0s infinite;
    animation: run 7s 0s infinite;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
    animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
}
.lines .line:nth-child(3) {
    margin-left: 25%;
}
.lines .line:nth-child(3)::after {
    -webkit-animation-delay: 2.5s;
    animation-delay: 2.5s;
}
.news-content-container{
    display: flex;
    margin-top: 6rem;
}
.news-left-content{
    width: 100%;
    padding-left: 0rem;
    padding-right: 6rem;
}
.news-right-content{
    width: 40%;
    padding-left: 2rem;
    padding-right: 0rem;
}
.malaysia-para{
    width: 100%;
}
/* .article-container{
    text-align: left;
    margin-top: 3rem;
} */
/* .article-container h1{
    text-align: center;
}
#head-para{
    text-align: center;
}
.article-container p{
    text-align: left;
}
.article-container h1{
    font-size: 3rem;
} */
/* .article-content{
    display: flex;
    padding: 1rem 5rem;
    justify-content: center;
} */
.news-img{
    width: 500px;
    height: 320px;
    transition: 0.5s;
}
.news-img:hover{
    opacity: 0.7;
    transform: scale(1.02);
    overflow: hidden;
}
.content-news-container{
    padding: 1rem 5.5rem 2rem 2rem;
}
.news-para{
    text-align: left;
}
.article-link{
    text-decoration: none;
    color: black;
    font-size: 1.3rem;
    font-weight: 600;
    margin-bottom: 1rem;
    text-align: justify;
}
.article-link:hover{
    color: black;
}
.read-btn{
    text-align: left;
    position: relative;
    text-decoration: none;
    color:#333333;
}
.read-btn::before {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 1px;
    bottom: -2px;
    left: 0;
    background-color: #000;
    transform: scaleX(0);
    transition: transform 0.3s ease;
  }
  .read-btn:hover::before {
    transform: scaleX(1);
  }
.read-btn:hover{
    color: #333;
}
/* .aeroplane-container,
.globe-container,
.pricing-container{
    margin: 0 1.2rem;
} */
.search-container{
    margin-top: 1rem;
    margin-bottom: 3rem;
}
.search-input{
    padding: 0.7rem 1.4rem;
    border: none;
    outline: none;
}
.search-input:focus{
    border: 1px solid black;
}
.fa-search{
    padding: 1rem;
    background-color: #1b2f5b;
    color: #fff;
    transition: 0.5s;
}
.fa-search:hover{
    background-color: tomato;
}
.widget-title{
    border-bottom: 1px solid #d3dbe2;
    padding-bottom: 10px;
    margin-bottom: 20px;
    text-transform: capitalize;
    position: relative;
    font-weight: 500;
    font-size: 20px;
}
.widget-title::before{
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 50px;
    height: 1px;
    background-color: #1b2f5b;
}
.annoucement-link{
    text-decoration: none;
    color: black;
    opacity: 0.8;
}
.annoucement-link:hover{
    color: black;
}
.recent-posts{
    margin-top: 6rem;
}
.news-link{
    text-decoration: none;
    color: #57647c;
    font-weight: 600;
    font-size: 1rem;
    transition: 0.5s;
    display: block;
    margin: 1rem 0;
    position: relative;
}
.news-link::before{
    content: '';
    position: absolute;
    height: 7px;
    width: 7px;
    left: -15px;
    top: 8px;
    background-color: #1b2f5b;
}
.news-link:hover{
    color: black;
    opacity: 1;
}
.recent-comments{
    margin-top: 6rem;
}
.categories{
    margin-top: 6rem;
}
.malaysia-para{
    color: #57647c;
}
strong{
    color: #000;
}
.news-left-content a{
    color: #57647c;
}
.news-left-content a:hover{
    text-decoration: none;
    color: #57647c;
}
.comment-form label{
    display: block;
    color: #333333;
    font-weight: 600;
    font-family: "Dosis", sans-serif;
}
.name-email{
    display: flex;
}
.comment-form input,textarea{
    padding:1rem ;
    border: none;
    outline: none;
    margin-right: 5rem;
}
.checkbox-container{
    display: inline;
}
.comment-form-container{
    margin: 3rem 0;
}
.comment-form-container p strong{
    font-family: "Dosis", sans-serif;
}
.comment-btn{
    background-color: #1b2f5b;
    color: #fff;
    margin-top: 2rem;
    margin-bottom: 3rem;
    font-weight: 600;
    border-radius: 5px;
    padding: 15px 25px;
    transition: 0.5s;
}
.comment-btn:hover{
    background-color: tomato;
}
@media only screen and (max-width:1025px){
    .comment-form input,textarea{
        padding:1rem ;
        margin-right: 0rem;
    }
    .comment-section textarea{
        margin: 0;
    }
}