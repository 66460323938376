
.about-container{
    position: relative;
}
.about-banner-area{
    margin-top: -6.5rem;
    position: relative;
    z-index: 1;
    padding-top: 210px;
    padding-bottom: 140px;
    background-color: #000000;
    background-position: center center !important;
    background-size: cover !important;
    background-repeat: no-repeat !important; 
} 
.about-banner-area::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: #000000;
    opacity: .60;
}
.about-links{
    margin: 0;
}
.about-links ul{
    display: flex;
    margin-left: -2rem;
}
.about-links ul li{
    list-style-type: none;
    font-weight: 600;
    color: #fff;
    margin-right: 0.5rem;
}
.home-link{
    text-decoration: none;
    color: tomato;
}
.home-link:hover{
    color: tomato;
    cursor: pointer;
}
.about-h2{
    color: #fff;
    font-weight: 600;
    font-size: 2.7rem;
}
.lines {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    margin: auto;
    width: 90vw;
    z-index: -1;
}
.lines .line:nth-child(1) {
    margin-left: -25%;
}
.lines .line {
    position: absolute;
    width: 1px;
    height: 100%;
    top: 0;
    left: 50%;
    background: rgba(255, 255, 255, 0.1);
    overflow: hidden;
}
.lines .line:nth-child(1)::after {
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
}
.lines .line::after {
    content: "";
    display: block;
    position: absolute;
    height: 15vh;
    width: 100%;
    top: -50%;
    left: 0;
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0)), color-stop(75%, #ffffff), to(#ffffff));
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #ffffff 75%, #ffffff 100%);
    -webkit-animation: run 7s 0s infinite;
    animation: run 7s 0s infinite;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
    animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
}
.lines .line:nth-child(3) {
    margin-left: 25%;
}
.lines .line:nth-child(3)::after {
    -webkit-animation-delay: 2.5s;
    animation-delay: 2.5s;
}
.about-area-1{
    display: flex;
    align-items: center;
    margin: 4rem 0;
}
.about-area-img{
    width: 50%;
}
.para-1,.para-2{
    text-align: justify;
}
.about-content-para{
    text-align: justify;
}
.revemaxlogo{
    width: 16rem;
    height: 23rem;
}
.about-area-intro{
    width: 50%;
}
.about-heading{
    font-weight: 600;
    font-size: 2.7rem;
}
.about-area-2{
    display: flex;
}
.about-text-content{
    margin: 0 1rem;
    width: 25%;
}
.about-heading-para strong{
    color:#7A7A7A;
}
.why-container{
    background: #FF4800;
    color: #fff;
    padding: 2rem;
}
.why-container .why-heading{
    text-align: center;
}
.why-content-container{
    display: flex;
    justify-content: space-between;
    padding: 1rem 3rem;
}
.why-content{
    width: 30%;
    margin: 1rem 0rem;
}
.why-para strong{
    font-size: 1.3rem;
    color: #fff;
}
.why-para-2{
    text-align: justify;
}
.team-container{
    background-color: #e4e9eb;
    padding: 5rem 1rem ;
}
.team-heading{
    text-align: center;
}
.team-card-container{
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.team-card{
    /* width: 15%; */
    height: auto;
    text-align: center;
    margin: 1.2rem;
    overflow: hidden;
    position: relative;
}
.team-img{
    width: auto;
    position: relative;
    height: 200px;
    transition: 0.5s ease;
    overflow: hidden;
}
.card-detail{
    position: relative;
    width: 100%;
    padding: 1.5rem 1.3rem 1rem 1.3rem;
    background-color: #fff;
    transition: 0.5s ease;
    overflow: hidden;
    text-align: center;
}
/* @media only screen and (max-width:1532px){
    .card-detail{
        height: 130px;
    }
}
    @media only screen and (max-width:1305px){
        .card-detail{
            height: 140px;
        }
    }
    @media only screen and (max-width:1245px){
        .card-detail{
            height: 150px;
        }
    } */
.card-name{
    font-size: 16px;
    text-align: center;
}
.team-card-container{
    display: flex;
    margin-top: 2rem;
}
.team-card:hover .team-img{
    transform: scale(1.25);
    overflow: hidden;
}
.team-card:hover .card-detail{
    background-color: #1b2f5b;
    color: #fff;
}
.social {
    position: absolute;
    top: 30%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 2;
    text-decoration: none;
    opacity: 0;
    margin-top: -100px;
    padding: 8px 15px;
    background: #ffffff;
    border-radius: 35px;
    visibility: hidden;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}
.social a{
    color: tomato;
    padding: 0 0.4rem;
}
.social a:hover{
    color: tomato;
}
.team-card:hover .social {
    opacity: 1;
    margin-top: 0;
    visibility: visible;
}
.flaticon-chat:before {
    content: "\f118";
    line-height: 1;
    font-size: 90px;
}
.client-container{
    background-color: #1b2f5b;
    text-align: center;
    /* padding:2rem 0; */
}
.client-heading{
    font-weight: 700;
    color: #fff;
}
.client-review{
    position: relative;
}

.client-heading-container{
    text-align: center;
}
.fa-wechat{
    color: #1b2f5b;
    font-size: 5rem;
    margin-top: 0.5rem;
}
.coming-soon{
    color: tomato;
    font-size: 1.4rem;
    font-weight: 700;
    margin-top: 2rem;
}
/* .carousel-inner{
    z-index: 0;
} */
.carousel-item::before{
    z-index: 0;
    display: none;
}

.review-caption{
    width: 40%;
    position: relative;
    margin: 3rem;
}
.review-caption > h2{
    color: tomato;
    font-size: 26px;
   
}
.review-caption > p{
    color: #fff;
    font-size: 20px;
    
}
.review-content{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5rem auto;
}
.review-content #myVideo1,#myVideo2,#myVideo3{
    z-index: 1000;
}
.carousel-control-next, .carousel-control-prev{
    width: 5% !important;
}
@media only screen and (min-width:1026px) and (max-width:1400px){
    .review-content iframe{
        width: 320px;
        height: auto;
    }
     .review-caption > h2{
        font-size: 20px;
    }
    .review-caption > p{
        font-size: 16px;
    }
}
@media only screen and (max-width:1025px){
    .review-content{
        display: block;
        margin: 2rem auto;
    }
    .review-caption{
        width: 70%;
        margin: 2rem auto;
    }
    .about-area-1{
        flex-direction: column-reverse;
    }
    .about-links{
        text-align: center;
        margin: 0;
    }
    .about-links ul{
        display: flex;
        justify-content: center;
    }
    .about-area-img{
        width: 70%;
        padding: 0rem;
        margin: 0 auto;
    }
    .revemaxlogo{
        width: 100%;
        height: auto;
    }
    .about-area-intro{
        width: 100%;
        padding:2rem 1rem;
    }
    .about-heading{
        font-weight: 600;
        font-size: 1.7rem;
    }
    .about-area-2{
        justify-content: center;
        flex-direction: column;
        padding: 2rem 1rem;
        width: 100%;
        text-align: center;
    }
    .about-text-content{
        width: 100%;
        margin: 0 auto;
    }
    .about-content-para{
        font-size: 0.8rem;
    }
    .why-container{
        padding: 0rem;
    }
    .why-content-container{
        flex-direction: column;
        padding: 0rem;
    }
    .why-content{
        width: 100%;
        padding: 1rem 2rem;
        margin: 0rem;
    }
    .why-heading{
        font-size: 2.5rem;
        padding: 2rem 0;
    }
  
    .client-container{
        padding: 2rem;
        text-align: center;
    }
    .client-heading{
        width: 100%;
        padding: 0;
    }
    .client-heading-container{
       padding-right: 0rem;
        text-align: center;
    }
    .fa-wechat{
        display: none;
    }
}
@media only screen and (min-width:320px) and (max-width:780px){
    .review-caption > h2{
        font-size: 20px;
    }
    .review-caption > p{
        font-size: 16px;
    }
    .review-content iframe{
        width: 100%;
        height: auto;
        z-index: 1000;
    }
    /* .carousel-control-prev,
    .carousel-control-next{
        display: none;
    } */
   
    .review-caption{
        width: 100%;
        position: relative;
        margin: 3rem auto;
    }

}
/* @media only screen and (max-width:720px){
.team-card-container{
    flex-direction: column;
    margin-top: 2rem;
}
.team-card{
    width: 100%;
    margin:1.5rem 0 ;
}
.team-img{
    width: 100%;
    height: auto;
}
} */