.consulting-container{
    position: relative;
}
.consulting-banner-area{
    margin-top: -6.5rem;
    position: relative;
    z-index: 1;
    padding-top: 210px;
    padding-bottom: 140px;
    background-color: #000000;
    background-position: center right !important;
    background-size:cover !important;
    background-repeat: no-repeat !important;
} 
.consulting-banner-area::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: #000000;
    opacity: .60;
}
.consulting-links{
    /* margin: -2rem 0 0 10rem; */
}
.consulting-links ul{
    display: flex;
    margin-left: -2rem;
}
.consulting-links ul li{
    list-style-type: none;
    font-weight: 600;
    color: #fff;
    margin-right: 0.5rem;
}
.home-link{
    text-decoration: none;
    color: tomato;
}
.home-link:hover{
    color: tomato;
    cursor: pointer;
}
.consulting-h2{
    color: #fff;
    font-weight: 600;
    font-size: 2.7rem;
}
.lines {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    margin: auto;
    width: 90vw;
    z-index: -1;
}
.lines .line:nth-child(1) {
    margin-left: -25%;
}
.lines .line {
    position: absolute;
    width: 1px;
    height: 100%;
    top: 0;
    left: 50%;
    background: rgba(255, 255, 255, 0.1);
    overflow: hidden;
}
.lines .line:nth-child(1)::after {
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
}
.lines .line::after {
    content: "";
    display: block;
    position: absolute;
    height: 15vh;
    width: 100%;
    top: -50%;
    left: 0;
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0)), color-stop(75%, #ffffff), to(#ffffff));
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #ffffff 75%, #ffffff 100%);
    -webkit-animation: run 7s 0s infinite;
    animation: run 7s 0s infinite;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
    animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
}
.lines .line:nth-child(3) {
    margin-left: 25%;
}
.lines .line:nth-child(3)::after {
    -webkit-animation-delay: 2.5s;
    animation-delay: 2.5s;
}
.consulting-para{
    text-align: justify;
    padding: 6.5rem;
    padding-bottom: 0;
}
.consulting-content-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 5rem 4rem;
}
.consulting-contents{
    width: 30%;
    padding: 2rem;
    background-color: #fff;
    box-shadow: 5px 5px #ffd1bf;
    margin: 1rem 0;
    border: 1px solid #ffd1bf;
    transition: 0.5s;
}
.consulting-contents:hover{
    box-shadow: 5px 5px #1b2f5b;
    border: 1px solid #1b2f5b;
}
.consulting-contents .fa{
    font-size: 3.2rem;
    color: #1b2f5b;
    margin-bottom: 1.1rem;
}
.consulting-content-links{
    text-decoration: none;
    color: black;
}
.consulting-content-links:hover{
    color: black;
}

@media only screen and (max-width:1025px){
    .consulting-links{
        margin: 0;
        text-align: center;
    }
    .consulting-links ul{
        justify-content: center;
        margin-left: -2rem;
    }
    .consulting-para{
        text-align: justify;
        padding: 6.5rem;
        padding-bottom: 0;
    }
    .consulting-content-container{
        display: flex;
        flex-wrap: wrap;
        justify-content: start;
        padding: 2rem 1rem;
    }
    .consulting-contents{
        width: 100%;
        padding: 1rem;
        margin: 1rem;
    }
}