@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@600&family=Roboto:wght@500&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}
.header-container{
    background-color: #1b2f5b;
    color: #fff;
    padding: 0.3rem;
    display: flex;
    top: 0;
    width: 100%;
    z-index: 1;
    justify-content: space-between;
}
.header-container .container{
    display: flex;
    justify-content: space-between;
    z-index: 100;
}
.header-left-content{
    margin-top: 0.5rem;
    margin-left: 0rem;
}
.header-right-content{
    margin-top: 0.5rem;
    margin-right: 0rem;
}
.header-right-content ul {
    display: flex;
}
.header-right-content ul li{
    list-style-type: none;
    margin-left: 1.2rem;
}
.header-right-content ul li a{
    text-decoration: none;
    color: #fff;
}
.header-right-content ul li a .fa{
    margin-right: 0.3rem;
}

@media only screen and (max-width:1025px){
    .header-container{
        display: none;
    }
}

.navbarMenu{
    position: sticky;
    max-width: 100%;
    height:auto;
    top: 0;
    align-items: center;
    z-index: 10; 
}
.navbarContainer{
    display: flex;
    justify-content: space-between;
    background: transparent;
    padding: 1rem;
}
.navbarMenu.active{
    background-color: #fff;
    color: #1b2f5b;
    z-index: 10;
}
.logo-container img {
    object-fit: contain;
    height: 60px;
}
.navbar-list {
    display: flex;
    align-items: center;
    max-width: 100%;
    list-style: none;
}
.navbar-list ul{
    display: flex;
    margin-bottom: 0;
}
.navbar-list ul li{
    list-style: none;
    margin-left: 1rem;
 
}
.navbar-list ul li a{
    text-decoration: none;
    color:#fff;
    font-weight: 600;
}
.navbar-list.active ul li a{
    color:#1b2f5b;
}
.navbar-list ul li a:hover{
    color: tomato;
}
.link.active-link{
    color: tomato;
}
.product.active-link{
    color: tomato;
}
.product{
    position: relative;
    font-weight: 600;
    color: #fff;
   
}
.product.active{
    color: #1b2f5b;
}

.product-links{
    position: absolute;
    width: 150px;
    height: 100px;
    display: none;
    top: 0rem;
    left: 0rem;
    background: #fff;
    padding: 0.5rem;
    text-align: left;
    margin-top: 1.5rem;
    align-items: center;
    z-index: 1;
}
.product:hover .product-links{
    display: block;
    opacity: 1;
}
.pro-link{
    margin: 0.8rem 0;
}
.product:hover{
    position: relative;
    color: tomato;
    cursor: pointer;
}
.product-link{
    text-decoration: none;
    margin:1rem;
    color: #1b2f5b !important;
}
.product-link:hover{
    color: tomato !important;
}
.connect-us{
    background-color:#fff;
    padding: 0.6rem 2rem;
    border-radius: 20px;
    margin-top: -0.5rem;
    color:#1b2f5b !important;
    transition: 0.5s ease;
}
.connect-us.active{
    background-color:#1b2f5b;
    color: #fff !important;
}
.connect-us:hover{
    background-color:#1b2f5b;
    color: #fff !important;
}
.connect-us.active:hover{
    background-color: tomato;
    color: #fff;
}

.openMenu {
    font-size: 1.7rem;
    display: none;
    cursor: pointer;
    color: #fff;
}
.openMenu.active{
    color: #000;
}
.closeMenu{
    font-size: 2rem;
    display: none;
    cursor: pointer;
    color: #000;
}

@media only screen and (max-width:1025px){
    .navbarMenu{
        background-color: #000;
        /* padding: 2rem; */
        justify-content: space-between;
        height: auto;
    }
    .logo-container img {
        height: 35px;
        margin-left: 0rem;
    }
    .navbar-list ul{
        flex-direction: column;
    }
    .navbar-list ul li{
        margin: 1rem;
        font-weight: 500;
    }
    .navbar-list ul li a{
        color: #677294;
    }
    .product{
        color: #677294;
    }
    .connect-us{
        display: none;
    }
    .navbar-list {
        height: 60vh;
        max-width: 100%;
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        z-index: 10;
        flex-direction: column;
        justify-content: center;
        align-items:flex-start;
        background:#fff;
        transition: top 1s ease;
        display: none;
    }
    .closeMenu {
        display: block;
        position: absolute;
        top: 3%;
        right: 12%;
    }
    .openMenu {
        display: block;
    }
    .product-links{
        position: absolute;
        width: 100px;
        height: 100px;
        display: none;
        top: -2rem;
        left: 100%;
        background: #fff;
        padding: 0.5rem;
        text-align: left;
        margin-top: 1.5rem;
        align-items: center;
        z-index: 1;
    }
    .product-link{
        font-size: 0.9rem;
        color: #677294 !important;
    }
}
.news-container{
    position: relative;
}
.news-banner-area{

    margin-top: -4.5rem;
    position: relative;
    z-index: 1;
    padding-top: 210px;
    padding-bottom: 140px;
    background-color: #000000;
    background-position: center center !important;
    background-size: cover !important;
    background-repeat: no-repeat !important; 
} 
.news-banner-area::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: #000000;
    opacity: .60;
}
.news-links{
    margin: 0;
}
.news-links ul{
    display: flex;
    margin-left: -2rem;
}
.news-links ul li{
    list-style-type: none;
    font-weight: 600;
    color: #fff;
    margin-right: 0.5rem;
}
.home-link{
    text-decoration: none;
    color: tomato;
}
.home-link:hover{
    color: tomato;
    cursor: pointer;
}
.news-h2{
    color: #fff;
    font-weight: 600;
    font-size: 2.7rem;
}
.lines {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    margin: auto;
    width: 90vw;
    z-index: -1;
}
.lines .line:nth-child(1) {
    margin-left: -25%;
}
.lines .line {
    position: absolute;
    width: 1px;
    height: 100%;
    top: 0;
    left: 50%;
    background: rgba(255, 255, 255, 0.1);
    overflow: hidden;
}
.lines .line:nth-child(1)::after {
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
}
.lines .line::after {
    content: "";
    display: block;
    position: absolute;
    height: 15vh;
    width: 100%;
    top: -50%;
    left: 0;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #ffffff 75%, #ffffff 100%);
    -webkit-animation: run 7s 0s infinite;
    animation: run 7s 0s infinite;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
    animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
}
.lines .line:nth-child(3) {
    margin-left: 25%;
}
.lines .line:nth-child(3)::after {
    -webkit-animation-delay: 2.5s;
    animation-delay: 2.5s;
}
.news-content-container{
    display: flex;
    margin-top: 6rem;
    margin: 0 auto;
}
#news-left-content {
    width: 60%;
    padding-left: 0rem;
    padding-right: 6rem;
}
#news-right-content{
    width: 40%;
    padding-left: 2rem;
    padding-right: 0rem;
}
#head-para{
    text-align: center;
}
.news-image{
    width: 70% !important;
    height: auto;
    transition: 0.5s;
}
.content-news-container{
    width: 70% !important;
    background-color:#f7f7f7;
    padding: 1rem 2rem;
}
.news-para{
    width: 100%;
    text-align: justify;
}
.article-link{
    text-decoration: none;
    color: black;
    font-size: 1.3rem;
    font-weight: 600;
    margin-bottom: 1rem;
    text-align: justify;
}
.article-link:hover{
    color: black;
}
.read-btn{
    text-align: left;
    position: relative;
    text-decoration: none;
    color:#333333;
}
.read-btn::before {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 1px;
    bottom: -2px;
    left: 0;
    background-color: #000;
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    transition: -webkit-transform 0.3s ease;
    transition: transform 0.3s ease;
    transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  }
  .read-btn:hover::before {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }
.read-btn:hover{
    color: #333;
}
.aeroplane-container,
.globe-container,
.pricing-container{
    width: 100%;
}
.search-container{
    margin-top: 1rem;
    margin-bottom: 3rem;
}
.search-input{
    padding: 0.7rem 1.4rem;
    border: none;
    outline: none;
}
.search-input:focus{
    border: 1px solid black;
}
.search-container .fa-search{
    padding: 1rem;
    background-color: #1b2f5b;
    color: #fff;
    transition: 0.5s;
}
.search-container .fa-search:hover{
    background-color: tomato;
}
.widget-title{
    border-bottom: 1px solid #d3dbe2;
    padding-bottom: 10px;
    margin-bottom: 20px;
    text-transform: capitalize;
    position: relative;
    font-weight: 500;
    font-size: 20px;
}
.widget-title::before{
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 50px;
    height: 1px;
    background-color: #1b2f5b;
}
.annoucement-link{
    text-decoration: none;
    color: black;
    opacity: 0.8;
}
.annoucement-link:hover{
    color: black;
}
.recent-posts{
    margin-top: 6rem;
}
.news-link{
    text-decoration: none;
    color: #57647c;
    font-weight: 600;
    font-size: 1rem;
    transition: 0.5s;
    display: block;
    margin: 1rem 0;
    position: relative;
}
.news-link::before{
    content: '';
    position: absolute;
    height: 7px;
    width: 7px;
    left: -15px;
    top: 8px;
    background-color: #1b2f5b;
}
.news-link:hover{
    color: black;
    opacity: 1;
}
.recent-comments{
    margin-top: 6rem;
}
.categories{
    margin: 6rem 0;
}

@media only screen and (max-width:1025px){
    .news-links{
        text-align: center !important;
        margin: 0 !important;
    }
    .news-links ul{
        justify-content: center !important;
    }
    .news-content-container{
        flex-direction: column;
        margin: 0rem auto;
    }
    .news-left-content{
        width: 100% !important;
        padding:1rem 0!important;
    }
    .news-right-content{
        width: 100% !important;
        padding: 1rem 3rem !important;
        margin: 0 auto;
    }
    .content-news-container{
        padding: 1rem !important;
        width: 100% !important;
    }
    .news-image{
        width: 100% !important;
        height: auto;
    }
    .search-container{
     margin: 3rem auto !important;
     text-align: center;
    }
    .search-input{
        padding: 0.7rem 2rem !important;
        width: 80%;
    }
}
.contact-container{
    position: relative;
}
.contact-banner-area{
    margin-top: -6.5rem;
    position: relative;
    z-index: 1;
    padding-top: 210px;
    padding-bottom: 140px;
    background-color: #000000;
    background-position: center center !important;
    background-size: cover !important;
    background-repeat: no-repeat !important; 
} 
.contact-banner-area::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: #000000;
    opacity: .60;
}
.contact-links{
    margin: 0;
}
.contact-links ul{
    display: flex;
    margin-left: -2rem;
}
.contact-links ul li{
    list-style-type: none;
    font-weight: 600;
    color: #fff;
    margin-right: 0.5rem;
}
.home-link{
    text-decoration: none;
    color: tomato;
}
.home-link:hover{
    color: tomato;
    cursor: pointer;
}
.contact-h2{
    color: #fff;
    font-weight: 600;
    font-size: 2.7rem;
}
.lines {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    margin: auto;
    width: 90vw;
    z-index: -1;
}
.lines .line:nth-child(1) {
    margin-left: -25%;
}
.lines .line {
    position: absolute;
    width: 1px;
    height: 100%;
    top: 0;
    left: 50%;
    background: rgba(255, 255, 255, 0.1);
    overflow: hidden;
}
.lines .line:nth-child(1)::after {
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
}
.lines .line::after {
    content: "";
    display: block;
    position: absolute;
    height: 15vh;
    width: 100%;
    top: -50%;
    left: 0;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #ffffff 75%, #ffffff 100%);
    -webkit-animation: run 7s 0s infinite;
    animation: run 7s 0s infinite;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
    animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
}
.lines .line:nth-child(3) {
    margin-left: 25%;
}
.lines .line:nth-child(3)::after {
    -webkit-animation-delay: 2.5s;
    animation-delay: 2.5s;
}
.contact-heading-container{
    text-align: center;
}
.contact-para{
    line-height: 0.1;
    font-weight: 600;
}
.contact-heading{
    font-size: 2.7rem;
}
.contact-form-container{
    padding:6rem;
}
.contact-form-content{
    display: flex;
    justify-content: center;
    padding: 2rem 0;
}
.contact-form-img{
    width: 50%;
    margin-right: 3rem;
}
.contact-image{
    width: 75%;
}
.contact-form{
    width: 50%;
    margin-left: 3rem;
    margin-top: 2.5rem;
}
.input-container input,textarea{
    border: none;
    padding: 1rem;
    margin-bottom: 1rem;
    font-weight: 400;
    opacity: 0.8;
}
.input-container textarea{
    border: none;
    padding: 1rem 3rem;
    margin-bottom: 1rem;
    font-weight: 400;
    opacity: 0.8;
}
.input-container input:active{
    border: none;
}
input[placeholder]{
    color: #333;
}
.send-btn{
    background-color:#1b2f5b;
    padding: 0.8rem 2.2rem;
    border-radius: 25px;
    color:#fff;
    transition: 0.5s ease;
    border: none;
    margin-left: 10rem;
    font-weight: 600;
    font-size: 1.1rem;
}
.send-btn:hover{
    background-color: tomato;
}
.contact-details-container{
    text-align: center;
    margin: 2rem;
}
.phone-number{
    color: #1b2f5b;
    text-decoration: none;
    font-size: 1.6rem;
    font-weight: 600;
    transition: 0.5s;
}
.phone-number:hover{
    color: tomato;
}
.or{
    color: #333;
    font-weight: 400;
    font-size: 1.2rem;
}
.contact-email{
    color: tomato;
    text-decoration: none;
    font-size: 1.6rem;
    transition: 0.5s;
}
.contact-email:hover{
    color: #1b2f5b;
}
.contact-icons{
    display: flex;
    justify-content: center;
    margin-top: 1rem;
}
.contact-icon{
    background-color: #fff;
    padding: 0.3rem 0.8rem;
    border-radius: 50%;
    margin: 0.2rem;
    transition: 0.5s ease;
    border: 1px solid rgb(163, 162, 162);
    opacity: 0.4;
}
.contact-icon a{
    color:#333 ;
}
.contact-icon a:hover{
    text-decoration: none;
    color: #fff;
}
.contact-icon:hover{
    background-color:#1b2f5b !important;
    cursor: pointer !important;
    color: #fff;
    opacity: 1;
}
.contact-icon:hover a{
    color: #fff;
}
/* ther is email checker thing  */

.display_message{
    display: block;
}
.notdisplay_message{
    display:none;
}
.display-container{
    width: 50%;
    margin:2rem 0;
    text-align: center;
    color:#1b2f5b ;
    font-weight: 600;
    font-size: 1.5rem;
}
#display-success{
    color: green;
}
#display-error{
    color: red;
}


.recaptcha{
    width: 50%;
    margin: 1rem 0 0 4rem;
    padding: 2rem;
}
.captcha-error{
    width: 50%;
    color: red;
    font-size: 1.3rem;
    margin: 1rem 0 2rem 10rem;
}

@media only screen and (max-width:1025px){

.recaptcha{
    width: 100%;
    margin: 0;
}
.captcha-error{
    width: 100%;
    text-align: center;
    margin: 1rem 0;
}
    .display-container{
        width: 100%;

        font-size: 1.2rem;
    }
    .contact-links{
        margin: 0;
        text-align: center;
    }
    .contact-links ul{
       justify-content: center;
    }
    .contact-form-content{
        flex-direction: column;
        padding: 0rem;
    }
    .input-container{
        flex-direction: column !important;
        padding: 0;
        width: 100%;
    }
    .contact-heading{
        font-size: 1.7rem;
    }
    .contact-form-container{
        padding:2rem 0;
        margin: 2rem 0;
        width: 100%;
        margin: auto;
    }
    .contact-form-img{
        margin: 0 auto;
        width: 90%;
    }
    .contact-image{
        width: 100%;
        height: auto;
    }
    .contact-form{
        text-align: left;
        margin: 2rem auto;
        width: 100%;
    }
    .send-message-btn{
        width: 80%;
        margin: 0 auto;
        text-align: center;
    }
    .send-btn{
       padding: 0.7rem 2.5rem;
       margin: 0 auto;
    }
    .input-container input,textarea{
        padding: 1rem 1.1rem;
        outline: none;
    }
    .input-container textarea{
        padding: 1rem 1.1rem;
    }
}

.arms-container{
    position: relative;
}
.arms-banner-area{
    margin-top: -6.5rem;
    position: relative;
    z-index: 1;
    padding-top: 210px;
    padding-bottom: 140px;
    background-color: #000000;
    background-position: center center !important;
    background-size: cover !important;
    background-repeat: no-repeat !important; 
} 
.arms-banner-area::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: #000000;
    opacity: .60;
}
.arms-links{
    /* margin: -2rem 0 0 10rem; */
}
.arms-links ul{
    display: flex;
    margin-left: -2rem;
}
.arms-links ul li{
    list-style-type: none;
    font-weight: 600;
    color: #fff;
    margin-right: 0.5rem;
}
.home-link{
    text-decoration: none;
    color: tomato;
}
.home-link:hover{
    color: tomato;
    cursor: pointer;
}
.arms-h2{
    color: #fff;
    font-weight: 600;
    font-size: 2.7rem;
}
.fa-angle-right{
    color: #fff;
}
.lines {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    margin: auto;
    width: 90vw;
    z-index: -1;
}
.lines .line:nth-child(1) {
    margin-left: -25%;
}
.lines .line {
    position: absolute;
    width: 1px;
    height: 100%;
    top: 0;
    left: 50%;
    background: rgba(255, 255, 255, 0.1);
    overflow: hidden;
}
.lines .line:nth-child(1)::after {
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
}
.lines .line::after {
    content: "";
    display: block;
    position: absolute;
    height: 15vh;
    width: 100%;
    top: -50%;
    left: 0;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #ffffff 75%, #ffffff 100%);
    -webkit-animation: run 7s 0s infinite;
    animation: run 7s 0s infinite;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
    animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
}
.lines .line:nth-child(3) {
    margin-left: 25%;
}
.lines .line:nth-child(3)::after {
    -webkit-animation-delay: 2.5s;
    animation-delay: 2.5s;
}
.arms-content-container{
    display: flex;
    justify-content: space-between;
    padding: 5rem 4rem;
}
.arms-contents{
    width: 30%;
    padding: 2rem;
    background-color: #fff;
    box-shadow: 5px 5px #ffd1bf;
    /* margin: 1rem 3rem; */
    border: 1px solid #ffd1bf;
    transition: 0.5s;
}
.arms-contents:hover{
    box-shadow: 5px 5px #1b2f5b;
    border: 1px solid #1b2f5b;
}
.arms-contents .fa{
    font-size: 3.2rem;
    color: #1b2f5b;
    margin-bottom: 1.1rem;
}
.arms-contents .fa-search{
    font-size: 3.2rem;
    color: #1b2f5b;
    background: #fff;
}
.arms-contents .fa-search:hover{
    background: none;
}
.arms-content-links{
    text-decoration: none;
    color: black;
}
.arms-content-links:hover{
    color: black;
}
@media only screen and (max-width:1025px){
    .arms-h2{
        font-weight: 400;
        font-size: 1.5rem;
    }
    .arms-links{
        margin: 0rem auto;
        text-align: center;
        padding: 2rem;
    }
    .arms-contents .fa{
        margin: 1.1rem 0;
    }
    .arms-links ul{
        display: flex;
        flex-direction: column;
        margin: 0;
        padding: 0;
    }
    .arms-content-container{
        flex-direction: column;
        margin: 0 auto;
        padding: 1rem;
    }
    .arms-contents{
        width: 100%;
        padding: 0rem 1rem;
        margin: 2rem auto;
    }
}
.arx-container{
    position: relative;
}
.arx-banner-area{
    margin-top: -6.5rem;
    position: relative;
    z-index: 1;
    padding-top: 210px;
    padding-bottom: 140px;
    background-color: #000000;
    background-position: center right !important;
    background-size:cover !important;
    background-repeat: no-repeat !important;
} 
.arx-banner-area::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: #000000;
    opacity: .60;
}
.arx-links{
    /* margin: -2rem 0 0 10rem; */
}
.arx-links ul{
    display: flex;
    margin-left: -2rem;
}
.arx-links ul li{
    list-style-type: none;
    font-weight: 600;
    color: #fff;
    margin-right: 0.5rem;
}
.home-link{
    text-decoration: none;
    color: tomato;
}
.home-link:hover{
    color: tomato;
    cursor: pointer;
}
.arx-h2{
    color: #fff;
    font-weight: 600;
    font-size: 2.7rem;
}
.lines {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    margin: auto;
    width: 90vw;
    z-index: -1;
}
.lines .line:nth-child(1) {
    margin-left: -25%;
}
.lines .line {
    position: absolute;
    width: 1px;
    height: 100%;
    top: 0;
    left: 50%;
    background: rgba(255, 255, 255, 0.1);
    overflow: hidden;
}
.lines .line:nth-child(1)::after {
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
}
.lines .line::after {
    content: "";
    display: block;
    position: absolute;
    height: 15vh;
    width: 100%;
    top: -50%;
    left: 0;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #ffffff 75%, #ffffff 100%);
    -webkit-animation: run 7s 0s infinite;
    animation: run 7s 0s infinite;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
    animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
}
.lines .line:nth-child(3) {
    margin-left: 25%;
}
.lines .line:nth-child(3)::after {
    -webkit-animation-delay: 2.5s;
    animation-delay: 2.5s;
}
.arx-para{
    text-align: justify;
    padding: 6.5rem;
    padding-bottom: 0;
}
.arx-content-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 5rem 4rem;
}
.arx-contents{
    width: 30%;
    padding: 2rem;
    background-color: #fff;
    box-shadow: 5px 5px #ffd1bf;
    margin: 1rem 0;
    border: 1px solid #ffd1bf;
    transition: 0.5s;
}
.arx-contents:hover{
    box-shadow: 5px 5px #1b2f5b;
    border: 1px solid #1b2f5b;
}
.arx-contents .fa{
    font-size: 3.2rem;
    color: #1b2f5b;
    margin-bottom: 1.1rem;
}
.arx-content-links{
    text-decoration: none;
    color: black;
}
.arx-content-links:hover{
    color: black;
}
@media only screen and (max-width:1025px){
    .arx-para{
        padding: 3rem 1rem;
        font-size: 0.8rem;
    }
    .arx-content-container{
        flex-direction: column;
        padding: 1rem;
    }
    .arx-contents{
        width: 100%;
        padding: 1rem;
        margin: 1.5rem 0;
    }

    .arx-links{
        text-align: center;
        margin: 0;
        padding: 0;
    }
    .arx-links ul{
        margin-left: -1.5rem;
        justify-content: center;
    }
}
.consulting-container{
    position: relative;
}
.consulting-banner-area{
    margin-top: -6.5rem;
    position: relative;
    z-index: 1;
    padding-top: 210px;
    padding-bottom: 140px;
    background-color: #000000;
    background-position: center right !important;
    background-size:cover !important;
    background-repeat: no-repeat !important;
} 
.consulting-banner-area::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: #000000;
    opacity: .60;
}
.consulting-links{
    /* margin: -2rem 0 0 10rem; */
}
.consulting-links ul{
    display: flex;
    margin-left: -2rem;
}
.consulting-links ul li{
    list-style-type: none;
    font-weight: 600;
    color: #fff;
    margin-right: 0.5rem;
}
.home-link{
    text-decoration: none;
    color: tomato;
}
.home-link:hover{
    color: tomato;
    cursor: pointer;
}
.consulting-h2{
    color: #fff;
    font-weight: 600;
    font-size: 2.7rem;
}
.lines {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    margin: auto;
    width: 90vw;
    z-index: -1;
}
.lines .line:nth-child(1) {
    margin-left: -25%;
}
.lines .line {
    position: absolute;
    width: 1px;
    height: 100%;
    top: 0;
    left: 50%;
    background: rgba(255, 255, 255, 0.1);
    overflow: hidden;
}
.lines .line:nth-child(1)::after {
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
}
.lines .line::after {
    content: "";
    display: block;
    position: absolute;
    height: 15vh;
    width: 100%;
    top: -50%;
    left: 0;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #ffffff 75%, #ffffff 100%);
    -webkit-animation: run 7s 0s infinite;
    animation: run 7s 0s infinite;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
    animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
}
.lines .line:nth-child(3) {
    margin-left: 25%;
}
.lines .line:nth-child(3)::after {
    -webkit-animation-delay: 2.5s;
    animation-delay: 2.5s;
}
.consulting-para{
    text-align: justify;
    padding: 6.5rem;
    padding-bottom: 0;
}
.consulting-content-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 5rem 4rem;
}
.consulting-contents{
    width: 30%;
    padding: 2rem;
    background-color: #fff;
    box-shadow: 5px 5px #ffd1bf;
    margin: 1rem 0;
    border: 1px solid #ffd1bf;
    transition: 0.5s;
}
.consulting-contents:hover{
    box-shadow: 5px 5px #1b2f5b;
    border: 1px solid #1b2f5b;
}
.consulting-contents .fa{
    font-size: 3.2rem;
    color: #1b2f5b;
    margin-bottom: 1.1rem;
}
.consulting-content-links{
    text-decoration: none;
    color: black;
}
.consulting-content-links:hover{
    color: black;
}

@media only screen and (max-width:1025px){
    .consulting-links{
        margin: 0;
        text-align: center;
    }
    .consulting-links ul{
        justify-content: center;
        margin-left: -2rem;
    }
    .consulting-para{
        text-align: justify;
        padding: 6.5rem;
        padding-bottom: 0;
    }
    .consulting-content-container{
        display: flex;
        flex-wrap: wrap;
        justify-content: start;
        padding: 2rem 1rem;
    }
    .consulting-contents{
        width: 100%;
        padding: 1rem;
        margin: 1rem;
    }
}
.num-container{
    display: flex;
    justify-content: center;
    margin-top: 2rem;
}
.number-container{
    margin: 0.4rem 2rem;
    text-align: center;
}
span{
    color: tomato;
    font-weight: 600;
    line-height: 1.3rem;
    font-size: 1.3rem;
}

@media only screen and (max-width:1025px){
    .num-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 2rem;
    }
}
.do-container{
    display: flex;
}
.img-container{
    width: 50%;
    position: relative;
    top: 4rem;
    left: 0rem;
}
.text-container{
    width: 50%;
    padding: 5rem 2.5rem;
    margin-top: 4.5rem;
    margin-left: 5rem;
    padding-right: 0;
}
.text-container p{
    margin-bottom: 1rem;
    text-align: justify;
}
.image-container-1{
   position: relative;
}
.image-container-2{
    position: absolute;
    top: 6.1rem;
    left: 11.5rem;
}
.discover-btn{
    background-color: #1b2f5b;
    padding: 0.6rem 2.5rem;
    border-radius: 1.5rem;
    text-decoration: none;
    color: #fff;
    transition: 0.5s ease;
    font-weight: 600;
}
.discover-btn:hover{
    background-color: tomato;
    color: #fff;
}
@media only screen and (max-width:1230px) {
    .do-container{
        flex-direction: column;
    }
    .img-container{
        width: 100%;
        margin-bottom: 5rem ;
    }
    .text-container{
        width: 100%;
        margin-top: 5rem;
    }

}

@media only screen and (max-width:1025px){
    .image-container-2{
        display: none;
    }
    .img-container{
        width: 100%;
        top: 0rem;
        left: 0rem;
        margin: 1rem auto;
    }
    .image-container-1{
        padding: 1rem;
    }
    .about-img{
        width:100%;
        height: auto;
     }
     .do-container{
        display: flex;
        flex-direction: column-reverse;
    }
    .text-container{
        width: 100%;
        padding: 1rem;
        margin: 1rem 0;
    }
    .discover-btn{
        padding: 0.6rem 2rem;
        font-weight: 500;
        font-size: 0.8rem;
    }
}


.slider{
    margin-top: -5rem;
    z-index: 0;
    height: auto;
    
    width: 100%;
    margin-top: -96px;
}

.main-banner-content{
    text-align: left;
    position: absolute;
    top: 50%;
    left:0;
    -webkit-transform: translate(10%,-250%);
            transform: translate(10%,-250%);
    font-family: 'Montserrat', sans-serif;
    line-height: 3rem;
}
.reach-out-btn{
    background-color:#1b2f5b;
    padding: 0.6rem 2rem;
    border-radius: 20px;
   color: #fff;
   text-decoration: none;
   z-index: 3000;
}
.slider .carousel-item::before{
    content: unset; 
}
.reach-out-btn:hover{
    background-color: tomato !important;
    color: #fff !important;
}
.heading{
    font-size: 4rem;
    letter-spacing: 1.5;
}
@media only screen and (max-width:1500px) {
    .main-banner-content{
        top: 250%;
        left:0;
        -webkit-transform: translate(10%,-250%);
                transform: translate(10%,-250%);
    }
}
@media only screen and (max-width:1150px) {
    .main-banner-content{
        top: 550%;
        left:0;
        -webkit-transform: translate(10%,-250%);
                transform: translate(10%,-250%);
    }
}
@media only screen and (max-width:1025px){
    .main-banner-content{
        /* top: -15rem;
        left: -1rem; */
        top: 30%;
        left:0;
        -webkit-transform: translate(10%,-180%);
                transform: translate(10%,-180%);
        padding: 0 0rem;
        line-height: 1.5rem;
    }
    .banner-img{
        height: 550px;
    }
    .heading{
        font-size: 2rem;
        letter-spacing: 1.1;
    }
    /* .button{
        font-size: 0.7rem;
        padding: 0.4rem 1rem;
    } */
    .main-banner-content p{
        font-size: 0.8rem;
        font-weight: 300;
    }
}
.solution{
    margin-top: 10rem;
    margin-bottom: 3rem;
}

.solution-heading{
    width: 50%;
    text-align: center;
    margin: 0 auto;
    font-size: 2.5rem;
    font-weight: 600;
}
.solution-container{
    margin-top: 3rem;
    display: flex;
}
.list-container{
    width: 100%;
    margin-right: 10rem;
    color: #fff;
    text-align: left;
    align-items: center;
}
.card-container{
    width: 100%;
}
.list-container .prediction{
    width: 100%;
    margin: 1rem auto;
    background-color: #fff;
    color: #1b2f5b;
    padding: 1rem 0 ;
    border-radius: 10px;
    align-self: center;
    cursor: pointer;
    transition: 0.5s;
}
.list-container .prediction.active{
   color: #fff;
   background-color: #1b2f5b;
}
.list-container .prediction.active .fa{
    background-color: #fff;
    color: #1b2f5b;
}
.list-container .prediction:hover{
    color: #fff;
   background-color: #1b2f5b;
}
.list-container .prediction .fa{
    font-size: 2rem;
    margin: 0 1.7rem;
    padding: 0.8rem;
    border-radius: 10px;
    background-color: #fff;
    color: #1b2f5b;
}
.list-container .prediction:hover .fa{
    background-color: #fff;
    color: #1b2f5b;
}
.card-container p{
    width: 100%;
    text-align: justify;
    margin-top: 1rem;
}
.readmore-button{
    background-color: #1b2f5b;
    color: #fff;
    padding: 0.6rem 2rem;
    border-radius: 2rem;
    text-decoration: none;
    margin-top: 2rem;
    transition: 0.6s ease;
}
.readmore-button:hover{
    background-color: tomato;
    color: #fff;
    font-weight: 600;
}
#predict{
    display: block;
}
#analysis{
    display: none;
}
#reporting{
    display: none;
}

@media only screen and (max-width:1025px){
    .solution-heading{
        width: 100%;
        font-size: 1.7rem;
        font-weight: 500;
    }
    .solution{
        margin-top: 3rem;
        margin-bottom: 3rem;
    }
    .solution-container{
       flex-direction: column;
       padding: 0 1rem ;
    }
    .list-container{
        width: 100%;
        margin: 1rem auto;
    }
    .list-container .prediction{
        width: 100%;
        margin-bottom: 1rem;
        font-size: 1rem;
    }
    .prediction .fa{
        font-size: 1rem;
        margin: 0 1rem
    }
    .card-container{
        width: 100%;
    }
    .card-container img{
        margin: 1rem auto;
        width: 100%;
        height: auto;
    }
    .card-container p{
        width: 100%;
        margin: 1rem 0 2rem 0;
        padding: 0 1rem;
        font-size: 0.9rem;
    }
    .readmore-button{
        padding: 0.7rem 1.6rem;
        font-size: 0.8rem;
        margin: 3rem 1rem;
    }
}
@import "";

.video{
    display: flex;
    margin-top: 6rem;
    position: relative;
}
.video-text-container{
    width: 50%;
    padding: 8rem 5rem;
    padding-left: 0;
}
.video-text-container h1{
    width: 100%;
    font-size: 2.5rem;
    font-weight: 600;
}
.video-text-container p{
    text-align: justify;
    margin-top: 1.2rem;
}
#startergy{
    color: tomato;
}
.video-img-container{
    width: 50%;
}
.video-img{
    height: 500px;
    width: 100%;
}
.play{
    position: absolute;
    height: 100px;
    width: 100px;
    left:50%;
    top: 50%;
    -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
    z-index: 1;
    background-color: #1b2f5b;
    color: #fff;
    padding: 2rem;
    border-radius: 50%;
    transition: 0.5s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.play .fa-play{
    font-size: 2.5rem;
    text-align: center;
}
.play:hover{
    background-color: tomato;
    color: #fff;
}
.play a{
    color: #fff;
    text-decoration: none;
}
.play::before,
.play::after{
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    bottom: 0;
    left: 0;
    border-radius: 50%;
    border: 1px solid #1b2f5b;
}
.play a:hover{
    color: #fff;
}
.play::before{
    -webkit-animation: ripple 1s linear 1s infinite;
          animation: ripple 1s linear 1s infinite;
}
.play::after{
    -webkit-animation: ripple 1s linear 1s infinite;
          animation: ripple 1s linear 1s infinite;
}

@-webkit-keyframes ripple {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    75% {
      -webkit-transform: scale(1.75);
              transform: scale(1.75);
      opacity: 0.7;
    }
    100% {
      -webkit-transform: scale(2);
              transform: scale(2);
      opacity: 0;
    }
  }

@keyframes ripple {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    75% {
      -webkit-transform: scale(1.75);
              transform: scale(1.75);
      opacity: 0.7;
    }
    100% {
      -webkit-transform: scale(2);
              transform: scale(2);
      opacity: 0;
    }
  }
  


@-webkit-keyframes modal-video {
    from {
        opacity: 0;
   }
    to {
        opacity: 1;
   }
}
  


@keyframes modal-video {
    from {
        opacity: 0;
   }
    to {
        opacity: 1;
   }
}
@-webkit-keyframes modal-video-inner {
    from {
        -webkit-transform: translate(0, 100px);
                transform: translate(0, 100px);
   }
    to {
        -webkit-transform: translate(0, 0);
                transform: translate(0, 0);
   }
}
@keyframes modal-video-inner {
    from {
        -webkit-transform: translate(0, 100px);
                transform: translate(0, 100px);
   }
    to {
        -webkit-transform: translate(0, 0);
                transform: translate(0, 0);
   }
}
.modal-video {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    z-index: 1000000;
    cursor: pointer;
    opacity: 1;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    -webkit-animation-duration: 0.3s;
            animation-duration: 0.3s;
    -webkit-animation-name: modal-video;
            animation-name: modal-video;
    transition: opacity 0.3s ease-out;
}
.modal-video-effect-exit {
    opacity: 0;
}
.modal-video-effect-exit .modal-video-movie-wrap {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px);
}
.modal-video-body {
    max-width: 960px;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    padding: 0 10px;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
}
.modal-video-inner {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}
@media (orientation: landscape) {
    .modal-video-inner {
        padding: 10px 60px;
        box-sizing: border-box;
   }
}
.modal-video-movie-wrap {
    width: 100%;
    height: 0;
    position: relative;
    padding-bottom: 56.25%;
    background-color: #333;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    -webkit-animation-duration: 0.3s;
            animation-duration: 0.3s;
    -webkit-animation-name: modal-video-inner;
            animation-name: modal-video-inner;
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
}
.modal-video-movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.modal-video-close-btn {
    position: absolute;
    z-index: 2;
    top: -45px;
    right: 0px;
    display: inline-block;
    width: 35px;
    height: 35px;
    overflow: hidden;
    border: none;
    background: transparent;
}
@media (orientation: landscape) {
    .modal-video-close-btn {
        top: 0;
        right: -45px;
   }
}
.modal-video-close-btn:before {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
}
.modal-video-close-btn:after {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
}
.modal-video-close-btn:before, .modal-video-close-btn:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px;
}



  @media only screen and (max-width:1025px){
      .video{
          flex-direction: column;
          position: relative;
      }
      .video-text-container{
        width: 100%;
        padding: 1rem;
        margin: 0 auto;
    }
    .video-text-container h1{
        width: 100%;
        font-size: 1.6rem;
    }
    .video-text-container p{
        font-size: 0.9rem;
    }
    #startergy{
        font-size: 1rem;
    }
    .video-img-container{
        width: 100%;
    }
    .video-img{
        width: 100%;
        height: auto;
    }
    .play{
        height: 50px;
        width: 50px;
        left:50%;
        top: 50%;
        -webkit-transform: translate(-50%,200%);
                transform: translate(-50%,200%);
        padding: 1rem;
    }
    .play .fa-play{
        font-size: 1.2rem;
    }
  }

.eco-container{
    margin-top: 3rem;
}
.eco-heading{
    text-align: center;
}
.eco-heading h1{
    font-size: 2.7rem;
}
.eco-image-heading{
    display: flex;
    width: cal(250px * 10);
    margin: 2rem auto;
    -webkit-animation: scroll 20s linear infinite;
            animation: scroll 20s linear infinite;
}
.eco-image-heading:hover{
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
}
@-webkit-keyframes scroll{
    0%{
        -webkit-transform:translateX(0px);
                transform:translateX(0px);
    }
    100%{
        -webkit-transform:translateX(calc(-250px * 5));
                transform:translateX(calc(-250px * 5));
    }
}
@keyframes scroll{
    0%{
        -webkit-transform:translateX(0px);
                transform:translateX(0px);
    }
    100%{
        -webkit-transform:translateX(calc(-250px * 5));
                transform:translateX(calc(-250px * 5));
    }
}
.eco-image{
    margin: 0 1rem;
    height: 200px;
    width: 250px;
    padding: 15px;
    display: flex;
    align-items: center;
    -webkit-perspective: 100px;
            perspective: 100px;
}
.eco-image img{
    width: 100%;
    transition: -webkit-transform 0.6s;
    transition: transform 0.6s;
    transition: transform 0.6s, -webkit-transform 0.6s;
}
img:hover{
    -webkit-transform: translateZ(10px);
            transform: translateZ(10px);
}
.slide-container{
    height: 250px;
    position: relative;
    overflow: hidden;
    width: 100%;
    margin: auto;
    display: grid;
    place-items: center;
    
}
@media only screen and (max-width:1025px){
    .eco-heading h1{
        font-size: 1.5rem;
    }
    .eco-heading p{
        font-size: 0.8rem;
    }
    .eco-container{
        margin: 3rem auto;
        width: 100%;
    }
    .slide-container{
        width: 90%;
        margin: 0 auto;
    }
    .eco-image{
        height: 150px;
        width: 200px;
    }
    .eco-image-heading{
        width: cal(100px * 10);
        margin: 2rem auto;
        -webkit-animation: scroll 20s linear infinite;
                animation: scroll 20s linear infinite;
    }
}
.article-container{
    text-align: left;
    margin-top: 3rem;
    padding: 3rem 0;
}
.article-container h1{
    text-align: center;
}
#head-para{
    text-align: center;
}
.article-container p{
    text-align: left;
}
.article-container h1{
    font-size: 3rem;
}
.article-content{
    display: flex;
    justify-content:space-between;
}
.article-img{
    width: 100%;
    height: auto;
}
.content-container{
    width: 100%;
    height: 330px;
    background-color:#f7f7f7;
    padding: 1rem 2rem;
}
.article-para{
    text-align: left;
}
.article-link{
    text-decoration: none;
    color: black;
    font-size: 1.3rem;
    font-weight: 600;
    margin-bottom: 1rem;
    text-align: justify;
}
.article-link:hover{
    color: black;
}
.read-btn{
    text-align: left;
    position: relative;
    text-decoration: none;
    color:#333333;
}
.read-btn::before {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 1px;
    bottom: -2px;
    left: 0;
    background-color: #000;
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    transition: -webkit-transform 0.3s ease;
    transition: transform 0.3s ease;
    transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  }
  .read-btn:hover::before {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }
.read-btn:hover{
    color: #333;
}
#aeroplane-container,
#globe-container,
#pricing-container{
    width: 30%;
    transition: 0.6s ease;
}
/* .aeroplane-container:hover,
.globe-container:hover,
.pricing-container:hover{
    margin: 0 1.2rem;
    width: 100%;
    transform: scale(1.02);
    overflow: hidden;
} */
@media only screen and (max-width:1025px){
    .article-content{
        flex-direction: column;
        padding: 1rem!important;
        margin: 1rem auto !important;
    }
    .article-container{
        margin: 1rem auto !important;
    }
    #industry-head{
        font-size: 1.4rem;
    }
    #head-para{
        font-size: 0.8rem;
    }
    .article-content .article-img{
        width: 100% !important;
        height: auto;
        margin: 0rem auto;
    }
    .article-link{
        font-size: 15px !important;
    }
    .aeroplane-container,
    .globe-container,
    .pricing-container{
    margin: 1rem auto!important;
    width: 100% !important;
    }
    .content-container{
        width: 100% !important;
        height: auto;
        margin: 0 auto !important;
        padding: 1rem !important;
    }
}
.reachout-container{
    width: 100%;
    height: auto;
    display: flex;
    position: relative;
    z-index: 1;
}
.reach-bg::before{
    background: url(/static/media/2.d56c0d14.png);
}
.reachout-container::before{
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    width: 63%;
    height: 100%;
    z-index: 1;
    background-position: left top;
    background-size: cover;
    background-repeat: no-repeat;
}
.woman-container{
    position: relative;
    width: 100%;
    height: 500px;
    background-position: center left;
    background-size: auto;
    background-repeat: no-repeat;
}
.woman-container img{
    height: 600px;
    width: 70%;
    display: none;
    background-size: cover;
    background-repeat: no-repeat;
}
.reach-text{
    z-index: 3;
    position: absolute;
    top: 15rem;
    left: 60%;
}
.reach-text h1{
    color: #fff;
}
.reach-text p{
    color: #fff;
}
.reach-text h1{
    font-weight: 800;
    font-size: 2.7rem;
}
.reach-text h4{
    margin-top: -0.725rem;
    margin-bottom: 1.3rem;
    font-size: 1.7rem;
    font-weight: 600;
}
@media only screen and (max-width:1025px){
    .reach-bg::before{
        background: none;
    }
    .reachout-container{
        flex-direction: column;
    }
    .woman-container{
        height: auto;
        background-image: none;
    }
    .woman-container img{
        height: auto;
        width: 100%;
        display: block;
        background-size: cover;
        background-repeat: no-repeat;
    }
    .reach-text{
        background-color:#1b2f5b ;
        top: 0rem;
        left: 0rem;
        padding: 3rem;
        position: relative;
        text-align: center;
    }
    .reachout-container::before{
        display: none;
    }
    .reach-text h1{
        font-size: 1.5rem;
        font-weight: 700;
    }
    .reach-text p{
        font-size: 0.8rem;
    }
}
.quotes-img{
    width: 100%;
    height: 500px;
    background-size: cover;
}
.quotes-para{
    text-align: left;
    font-size: 1.3rem;
}
.fa-quote-left{
    font-size: 4rem;
    position: absolute;
    left: 0;
    top: -3rem;
    opacity: 0.5;
}
.quotes-content h5{
    font-weight: 700;
    text-align: left;
}
.quotes-content{
    position: absolute;
    width: 40%;
    top: 11rem;
}
.quotes-container{
    position: relative;
    width: 100%;
    height: auto;
}
.quotes-container .carousel-fade .carousel-item{
    width: 100%;
    height: 600px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}
.quotes-container .carousel-fade .carousel-item img{
    display: none;
}
.carousel-item::before{
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    left: -30%;
    z-index: 99;
    background-position: left;
    background-size: contain;
    background-repeat: no-repeat;
}

.coo-para{
    text-align: left;
}
@media only screen and (max-width:1025px){
    .quotes-content{
        width: 100%;
        position: relative;
        margin: 0 auto;
        padding:0rem;
        top: 2rem;
    }
    .quotes-container .carousel-fade .carousel-item{
        width: 100%;
        height: 450px;
    }
    .quotes-container .carousel-fade .carousel-caption {
        position: absolute;
        top: 50%;
        bottom: unset;
        left: 50% !important;
        padding-top: 0;
        padding-bottom: 0;
        -webkit-transform: translate(-50%,-50%);
                transform: translate(-50%,-50%);
        color: #fff;
        text-align: center;
        width: 80%;
    }
    .quotes-para{
        position: relative;
        text-align: left;
        font-size: 0.8rem;
        margin: 0;
        padding:0rem;
        text-align: justify;
    }
    .quotes-container{
        background-color: #1b2f5b;
        padding: 0rem ;
        margin: 0 auto;
    }
    .fa-quote-left{
        font-size: 1.2rem;
        top: -1rem;
    }
    .quotes-img{
        position: relative;
        height: 70px;
        width: 120px;
        border-radius: 10px;
        text-align: left;
        margin: 15rem 0 0 3rem;
    }
    .quotes-content h5{
        margin-top: 1rem;
        font-size: 1rem;
    }
    
}


.about-container{
    position: relative;
}
.about-banner-area{
    margin-top: -6.5rem;
    position: relative;
    z-index: 1;
    padding-top: 210px;
    padding-bottom: 140px;
    background-color: #000000;
    background-position: center center !important;
    background-size: cover !important;
    background-repeat: no-repeat !important; 
} 
.about-banner-area::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: #000000;
    opacity: .60;
}
.about-links{
    margin: 0;
}
.about-links ul{
    display: flex;
    margin-left: -2rem;
}
.about-links ul li{
    list-style-type: none;
    font-weight: 600;
    color: #fff;
    margin-right: 0.5rem;
}
.home-link{
    text-decoration: none;
    color: tomato;
}
.home-link:hover{
    color: tomato;
    cursor: pointer;
}
.about-h2{
    color: #fff;
    font-weight: 600;
    font-size: 2.7rem;
}
.lines {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    margin: auto;
    width: 90vw;
    z-index: -1;
}
.lines .line:nth-child(1) {
    margin-left: -25%;
}
.lines .line {
    position: absolute;
    width: 1px;
    height: 100%;
    top: 0;
    left: 50%;
    background: rgba(255, 255, 255, 0.1);
    overflow: hidden;
}
.lines .line:nth-child(1)::after {
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
}
.lines .line::after {
    content: "";
    display: block;
    position: absolute;
    height: 15vh;
    width: 100%;
    top: -50%;
    left: 0;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #ffffff 75%, #ffffff 100%);
    -webkit-animation: run 7s 0s infinite;
    animation: run 7s 0s infinite;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
    animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
}
.lines .line:nth-child(3) {
    margin-left: 25%;
}
.lines .line:nth-child(3)::after {
    -webkit-animation-delay: 2.5s;
    animation-delay: 2.5s;
}
.about-area-1{
    display: flex;
    align-items: center;
    margin: 4rem 0;
}
.about-area-img{
    width: 50%;
}
.para-1,.para-2{
    text-align: justify;
}
.about-content-para{
    text-align: justify;
}
.revemaxlogo{
    width: 16rem;
    height: 23rem;
}
.about-area-intro{
    width: 50%;
}
.about-heading{
    font-weight: 600;
    font-size: 2.7rem;
}
.about-area-2{
    display: flex;
}
.about-text-content{
    margin: 0 1rem;
    width: 25%;
}
.about-heading-para strong{
    color:#7A7A7A;
}
.why-container{
    background: #FF4800;
    color: #fff;
    padding: 2rem;
}
.why-container .why-heading{
    text-align: center;
}
.why-content-container{
    display: flex;
    justify-content: space-between;
    padding: 1rem 3rem;
}
.why-content{
    width: 30%;
    margin: 1rem 0rem;
}
.why-para strong{
    font-size: 1.3rem;
    color: #fff;
}
.why-para-2{
    text-align: justify;
}
.team-container{
    background-color: #e4e9eb;
    padding: 5rem 1rem ;
}
.team-heading{
    text-align: center;
}
.team-card-container{
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.team-card{
    /* width: 15%; */
    height: auto;
    text-align: center;
    margin: 1.2rem;
    overflow: hidden;
    position: relative;
}
.team-img{
    width: auto;
    position: relative;
    height: 200px;
    transition: 0.5s ease;
    overflow: hidden;
}
.card-detail{
    position: relative;
    width: 100%;
    padding: 1.5rem 1.3rem 1rem 1.3rem;
    background-color: #fff;
    transition: 0.5s ease;
    overflow: hidden;
    text-align: center;
}
/* @media only screen and (max-width:1532px){
    .card-detail{
        height: 130px;
    }
}
    @media only screen and (max-width:1305px){
        .card-detail{
            height: 140px;
        }
    }
    @media only screen and (max-width:1245px){
        .card-detail{
            height: 150px;
        }
    } */
.card-name{
    font-size: 16px;
    text-align: center;
}
.team-card-container{
    display: flex;
    margin-top: 2rem;
}
.team-card:hover .team-img{
    -webkit-transform: scale(1.25);
            transform: scale(1.25);
    overflow: hidden;
}
.team-card:hover .card-detail{
    background-color: #1b2f5b;
    color: #fff;
}
.social {
    position: absolute;
    top: 30%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 2;
    text-decoration: none;
    opacity: 0;
    margin-top: -100px;
    padding: 8px 15px;
    background: #ffffff;
    border-radius: 35px;
    visibility: hidden;
    transition: 0.5s;
}
.social a{
    color: tomato;
    padding: 0 0.4rem;
}
.social a:hover{
    color: tomato;
}
.team-card:hover .social {
    opacity: 1;
    margin-top: 0;
    visibility: visible;
}
.flaticon-chat:before {
    content: "\f118";
    line-height: 1;
    font-size: 90px;
}
.client-container{
    background-color: #1b2f5b;
    text-align: center;
    /* padding:2rem 0; */
}
.client-heading{
    font-weight: 700;
    color: #fff;
}
.client-review{
    position: relative;
}

.client-heading-container{
    text-align: center;
}
.fa-wechat{
    color: #1b2f5b;
    font-size: 5rem;
    margin-top: 0.5rem;
}
.coming-soon{
    color: tomato;
    font-size: 1.4rem;
    font-weight: 700;
    margin-top: 2rem;
}
/* .carousel-inner{
    z-index: 0;
} */
.carousel-item::before{
    z-index: 0;
    display: none;
}

.review-caption{
    width: 40%;
    position: relative;
    margin: 3rem;
}
.review-caption > h2{
    color: tomato;
    font-size: 26px;
   
}
.review-caption > p{
    color: #fff;
    font-size: 20px;
    
}
.review-content{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5rem auto;
}
.review-content #myVideo1,#myVideo2,#myVideo3{
    z-index: 1000;
}
.carousel-control-next, .carousel-control-prev{
    width: 5% !important;
}
@media only screen and (min-width:1026px) and (max-width:1400px){
    .review-content iframe{
        width: 320px;
        height: auto;
    }
     .review-caption > h2{
        font-size: 20px;
    }
    .review-caption > p{
        font-size: 16px;
    }
}
@media only screen and (max-width:1025px){
    .review-content{
        display: block;
        margin: 2rem auto;
    }
    .review-caption{
        width: 70%;
        margin: 2rem auto;
    }
    .about-area-1{
        flex-direction: column-reverse;
    }
    .about-links{
        text-align: center;
        margin: 0;
    }
    .about-links ul{
        display: flex;
        justify-content: center;
    }
    .about-area-img{
        width: 70%;
        padding: 0rem;
        margin: 0 auto;
    }
    .revemaxlogo{
        width: 100%;
        height: auto;
    }
    .about-area-intro{
        width: 100%;
        padding:2rem 1rem;
    }
    .about-heading{
        font-weight: 600;
        font-size: 1.7rem;
    }
    .about-area-2{
        justify-content: center;
        flex-direction: column;
        padding: 2rem 1rem;
        width: 100%;
        text-align: center;
    }
    .about-text-content{
        width: 100%;
        margin: 0 auto;
    }
    .about-content-para{
        font-size: 0.8rem;
    }
    .why-container{
        padding: 0rem;
    }
    .why-content-container{
        flex-direction: column;
        padding: 0rem;
    }
    .why-content{
        width: 100%;
        padding: 1rem 2rem;
        margin: 0rem;
    }
    .why-heading{
        font-size: 2.5rem;
        padding: 2rem 0;
    }
  
    .client-container{
        padding: 2rem;
        text-align: center;
    }
    .client-heading{
        width: 100%;
        padding: 0;
    }
    .client-heading-container{
       padding-right: 0rem;
        text-align: center;
    }
    .fa-wechat{
        display: none;
    }
}
@media only screen and (min-width:320px) and (max-width:780px){
    .review-caption > h2{
        font-size: 20px;
    }
    .review-caption > p{
        font-size: 16px;
    }
    .review-content iframe{
        width: 100%;
        height: auto;
        z-index: 1000;
    }
    /* .carousel-control-prev,
    .carousel-control-next{
        display: none;
    } */
   
    .review-caption{
        width: 100%;
        position: relative;
        margin: 3rem auto;
    }

}
/* @media only screen and (max-width:720px){
.team-card-container{
    flex-direction: column;
    margin-top: 2rem;
}
.team-card{
    width: 100%;
    margin:1.5rem 0 ;
}
.team-img{
    width: 100%;
    height: auto;
}
} */
.footer-container{
    background-color: #15171a;
    padding: 3rem 0;
    display: flex;
    position: relative;
}
.footer-info{
    width: 25%;
    color: #fff;
    text-align: justify;
    padding: 1rem;
}
.footer-info img{
    width: 30px;
    margin-bottom: 1rem;
}
.social-icons{
    display: flex;
    justify-content: start;
}
.icon{
    background-color: #333333;
    padding: 0.3rem 0.8rem;
    border-radius: 50%;
    margin: 0.2rem;
    transition: 0.5s ease;
}
.icon:hover{
    background-color:#1b2f5b !important;
    cursor: pointer !important;
}
.icon a{
    color: #fff;
}
.icon a:hover{
    color: #fff;
}
.footer-quick-links{
    width: 25%;
    margin-left: 7rem;
}
h4{
    color: #fff;
    position: relative;
}
h4::before{
    content: '';
    position: absolute;
    left: 0;
    bottom: -0.5rem;
    width: 40px;
    height: 2px;
    background-color: #1b2f5b;
}
.quick-links,
.footer-product-links{
    margin-top: 2rem;
}
.quick-links ul li,
.footer-product-links ul li{
    list-style: none;
    margin-top:1rem ;
    margin-left: -2rem;
}
.quick-links ul li a{
    color: #fff;
    text-decoration: none;
    transition: 0.5s ease;
}
.footer-product{
    margin-left: 6rem;
    z-index: 10;
}
.footer-product-links ul li a{
    color: #fff !important;
    text-decoration: none;
    transition: 0.5s ease;
    
    
}
.quick-links ul li a:hover{
    color: tomato;
    cursor: pointer;
}
.footer-product-links ul li .foo-pro-links:hover{
    color: tomato !important; 
}
.circle-map {
    position: absolute;
    top: 20%;
    right: 18%;
    z-index: 2;
}
.circle-map img {
    max-width: 100%;
    height: auto;
    z-index: 0;
}
.circle-map img{
    vertical-align: middle;
    border-style: none;
}
.lines {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    margin: auto;
    width: 90vw;
    z-index: 3;
}
.lines .line {
    position: absolute;
    width: 1px;
    height: 100%;
    top: 0;
    left: 50%;
    background: rgba(255, 255, 255, 0.1);
    overflow: hidden;
}
.lines .line::after {
    content: "";
    display: block;
    position: absolute;
    height: 15vh;
    width: 100%;
    top: -50%;
    left: 0;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #ffffff 75%, #ffffff 100%);
    -webkit-animation: run 7s 0s infinite;
    animation: run 7s 0s infinite;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
    animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
}
@-webkit-keyframes run {
    0% {
      -webkit-transform: translateY(0vh);
              transform: translateY(0vh);
    }
    75% {
      -webkit-transform: translateY(130vh);
              transform: translateY(130vh);
    }
    100% {
      -webkit-transform: translateY(130vh);
              transform: translateY(130vh);
    }
  }
@keyframes run {
    0% {
      -webkit-transform: translateY(0vh);
              transform: translateY(0vh);
    }
    75% {
      -webkit-transform: translateY(130vh);
              transform: translateY(130vh);
    }
    100% {
      -webkit-transform: translateY(130vh);
              transform: translateY(130vh);
    }
  }
.lines .line:nth-child(1) {
    margin-left:-16rem;
}
.lines .line:nth-child(1)::after {
    -webkit-animation-delay: 2.5s;
    animation-delay: 2.5s;
}
.lines .line:nth-child(3) {
    margin-left: 25%;
}
.lines .line:nth-child(3)::after {
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
}
.copyright-container{
    background-color: #000000;
    padding: 1.2rem 5rem;
}
.copyright-container p{
    color: #fff;
}
.copyright-container p a{
    text-decoration: none;
    color: #fff;
    transition: 0.5s;
}
.copyright-container p a:hover{
    color: tomato;
}
.go-top {
    position: fixed;
    cursor: pointer;
    top: 0;
    right: 2%;
    color: #ffffff;
    z-index: 4;
    width: 40px;
    text-align: center;
    height: 42px;
    line-height: 42px;
    opacity: 0;
    visibility: hidden;
    transition: .9s;
    background-color: #1b2f5b;
  }
  
  .go-top.active {
    top: 98%;
    -webkit-transform: translateY(-98%);
            transform: translateY(-98%);
    opacity: 1;
    visibility: visible;
  }
  
  .go-top i {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: 0 auto;
    transition: 0.5s;
  }
  
  .go-top i:last-child {
    opacity: 0;
    visibility: hidden;
    top: 60%;
  }
  
  .go-top::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0;
    visibility: hidden;
    transition: 0.5s;
    background-color: #ff4800;
  }
  
  .go-top:hover, .go-top:focus {
    color: #ffffff;
  }
  
  .go-top:hover::before, .go-top:focus::before {
    opacity: 1;
    visibility: visible;
  }
  
  .go-top:hover i:first-child, .go-top:focus i:first-child {
    opacity: 0;
    top: 0;
    visibility: hidden;
  }
  
  .go-top:hover i:last-child, .go-top:focus i:last-child {
    opacity: 1;
    visibility: visible;
    top: 50%;
  }
  .fa-arrow-up:before {
    content: "\f062";
}
  
  @media only screen and (max-width:1025px){
    .footer-container{
        padding: 0rem;
        flex-direction: column;
    }
    .footer-info{
        width: 60%;
    }
    .footer-heading h4{
        margin-left:1rem ;
    }
    .footer-quick-links{
        width: 100%;
      margin: 1rem 0;
    }
    .footer-product-links{
        width: 100%;
      margin: 1rem 0;
    }
    .footer-product{
        width: 100%;
        margin: 1rem 0;
        padding: 1rem;
        
    }
    .quick-links ul li,
    .footer-product-links ul li{
        list-style: none;
        margin-top:0.5rem ;
        margin-left: 0rem;
        margin-right: 0;
    }
    .copyright-container{
        padding: 0.8rem  0;
        text-align: center;
    }
    .copyright-container p{
        font-size: 0.8rem;
    }
    .go-top {
        top: 0;
        right: 10%;
        width: 30px;
        height: 30px;
      }
      .circle-map {
        position: absolute;
        top: 0%;
        right: 0%;
        z-index: 2;
    }
  }
  
.prediction-container{
    position: relative;
}
.prediction-banner-area{
    margin-top: -6.5rem;
    position: relative;
    z-index: 1;
    padding-top: 210px;
    padding-bottom: 140px;
    background-color: #000000;
    background-position: center right !important;
    background-size:cover !important;
    background-repeat: no-repeat !important;
} 
.prediction-banner-area::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: #000000;
    opacity: .60;
}
.prediction-links{
    /* margin: -2rem 0 0 10rem; */
}
.prediction-links ul{
    display: flex;
    margin-left: -2rem;
}
.prediction-links ul li{
    list-style-type: none;
    font-weight: 600;
    color: #fff;
    margin-right: 0.5rem;
}
.home-link{
    text-decoration: none;
    color: tomato;
}
.home-link:hover{
    color: tomato;
    cursor: pointer;
}
.prediction-h2{
    color: #fff;
    font-weight: 600;
    font-size: 2.7rem;
}
.lines {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    margin: auto;
    width: 90vw;
    z-index: -1;
}
.lines .line:nth-child(1) {
    margin-left: -25%;
}
.lines .line {
    position: absolute;
    width: 1px;
    height: 100%;
    top: 0;
    left: 50%;
    background: rgba(255, 255, 255, 0.1);
    overflow: hidden;
}
.lines .line:nth-child(1)::after {
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
}
.lines .line::after {
    content: "";
    display: block;
    position: absolute;
    height: 15vh;
    width: 100%;
    top: -50%;
    left: 0;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #ffffff 75%, #ffffff 100%);
    -webkit-animation: run 7s 0s infinite;
    animation: run 7s 0s infinite;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
    animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
}
.lines .line:nth-child(3) {
    margin-left: 25%;
}
.lines .line:nth-child(3)::after {
    -webkit-animation-delay: 2.5s;
    animation-delay: 2.5s;
}
.prediction-content{
    padding: 6rem;
}
.prediction-heading{
    display: flex;
    margin-bottom: 3rem;
}
.prediction-heading .fa{
    font-size: 1.8rem;
    color: #fff;
    background-color: #282560;
    padding: 1rem;
    border-radius: 10px;
}
.prediction-heading .prediction-title{
    margin-left: 1rem;
    margin-top: 0.6rem;
    color: #282560;
}
.prediction-para{
    text-align: justify;
}
@media only screen and (max-width:1025px){
    .prediction-links{
        margin: 0;
        text-align: center;
    }
    .prediction-links ul{
        justify-content: center;
        margin-left: -2rem;

    }
    .prediction-content{
        padding: 3rem 1rem;
    }
}
.analysis-container{
    position: relative;
}
.analysis-banner-area{
    margin-top: -6.5rem;
    position: relative;
    z-index: 1;
    padding-top: 210px;
    padding-bottom: 140px;
    background-color: #000000;
    background-position: center right !important;
    background-size:cover !important;
    background-repeat: no-repeat !important;
} 
.analysis-banner-area::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: #000000;
    opacity: .60;
}
.analysis-links{
    /* margin: -2rem 0 0 10rem; */
}
.analysis-links ul{
    display: flex;
    margin-left: -2rem;
}
.analysis-links ul li{
    list-style-type: none;
    font-weight: 600;
    color: #fff;
    margin-right: 0.5rem;
}
.home-link{
    text-decoration: none;
    color: tomato;
}
.home-link:hover{
    color: tomato;
    cursor: pointer;
}
.analysis-h2{
    color: #fff;
    font-weight: 600;
    font-size: 2.7rem;
}
.lines {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    margin: auto;
    width: 90vw;
    z-index: -1;
}
.lines .line:nth-child(1) {
    margin-left: -25%;
}
.lines .line {
    position: absolute;
    width: 1px;
    height: 100%;
    top: 0;
    left: 50%;
    background: rgba(255, 255, 255, 0.1);
    overflow: hidden;
}
.lines .line:nth-child(1)::after {
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
}
.lines .line::after {
    content: "";
    display: block;
    position: absolute;
    height: 15vh;
    width: 100%;
    top: -50%;
    left: 0;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #ffffff 75%, #ffffff 100%);
    -webkit-animation: run 7s 0s infinite;
    animation: run 7s 0s infinite;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
    animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
}
.lines .line:nth-child(3) {
    margin-left: 25%;
}
.lines .line:nth-child(3)::after {
    -webkit-animation-delay: 2.5s;
    animation-delay: 2.5s;
}
.analysis-content{
    padding: 6rem;
}
.analysis-heading{
    display: flex;
    margin-bottom: 3rem;
}
.analysis-heading .fa{
    font-size: 1.8rem;
    color: #fff;
    background-color: #282560;
    padding: 1rem;
    border-radius: 10px;
}
.analysis-heading .analysis-title{
    margin-left: 1rem;
    margin-top: 0.6rem;
    color: #282560;
}
.analysis-para{
    text-align: justify;
}
@media only screen and (max-width:1025px){
    .analysis-links{
        margin: 0;
        text-align: center;
    }
    .analysis-links ul{
        justify-content: center;
        margin-left: -2rem;

    }
    .analysis-content{
        padding: 3rem 1rem;
    }
}
.reporting-container{
    position: relative;
}
.reporting-banner-area{
    margin-top: -6.5rem;
    position: relative;
    z-index: 1;
    padding-top: 210px;
    padding-bottom: 140px;
    background-color: #000000;
    background-position: center right !important;
    background-size:cover !important;
    background-repeat: no-repeat !important;
} 
.reporting-banner-area::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: #000000;
    opacity: .60;
}
.reporting-links{
    /* margin: -2rem 0 0 10rem; */
}
.reporting-links ul{
    display: flex;
    margin-left: -2rem;
}
.reporting-links ul li{
    list-style-type: none;
    font-weight: 600;
    color: #fff;
    margin-right: 0.5rem;
}
.home-link{
    text-decoration: none;
    color: tomato;
}
.home-link:hover{
    color: tomato;
    cursor: pointer;
}
.reporting-h2{
    color: #fff;
    font-weight: 600;
    font-size: 2.7rem;
}
.lines {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    margin: auto;
    width: 90vw;
    z-index: -1;
}
.lines .line:nth-child(1) {
    margin-left: -25%;
}
.lines .line {
    position: absolute;
    width: 1px;
    height: 100%;
    top: 0;
    left: 50%;
    background: rgba(255, 255, 255, 0.1);
    overflow: hidden;
}
.lines .line:nth-child(1)::after {
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
}
.lines .line::after {
    content: "";
    display: block;
    position: absolute;
    height: 15vh;
    width: 100%;
    top: -50%;
    left: 0;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #ffffff 75%, #ffffff 100%);
    -webkit-animation: run 7s 0s infinite;
    animation: run 7s 0s infinite;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
    animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
}
.lines .line:nth-child(3) {
    margin-left: 25%;
}
.lines .line:nth-child(3)::after {
    -webkit-animation-delay: 2.5s;
    animation-delay: 2.5s;
}
.reporting-content{
    padding: 6rem;
}
.reporting-heading{
    display: flex;
    margin-bottom: 3rem;
}
.reporting-heading .fa{
    font-size: 1.8rem;
    color: #fff;
    background-color: #282560;
    padding: 1rem;
    border-radius: 10px;
}
.reporting-heading .reporting-title{
    margin-left: 1rem;
    margin-top: 0.6rem;
    color: reporting
}
.reporting-para{
    text-align: justify;
}
@media only screen and (max-width:1025px){
    .reporting-links{
        margin: 0;
        text-align: center;
    }
    .reporting-links ul{
        justify-content: center;
        margin-left: -2rem;

    }
    .reporting-content{
        padding: 3rem 1rem;
    }
}
.upgrade-container{
    position: relative;
}
.upgrade-banner-area{
    margin-top: -6.5rem;
    position: relative;
    z-index: 1;
    padding-top: 210px;
    padding-bottom: 140px;
    background-color: #000000;
    background-position: center right !important;
    background-size:cover !important;
    background-repeat: no-repeat !important;
} 
.upgrade-banner-area::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: #000000;
    opacity: .60;
}
.upgrade-links{
    /* margin: -2rem 0 0 10rem; */
}
.upgrade-links ul{
    display: flex;
    margin-left: -2rem;
}
.upgrade-links ul li{
    list-style-type: none;
    font-weight: 600;
    color: #fff;
    margin-right: 0.5rem;
}
.home-link{
    text-decoration: none;
    color: tomato;
}
.home-link:hover{
    color: tomato;
    cursor: pointer;
}
.upgrade-h2{
    color: #fff;
    font-weight: 600;
    font-size: 2.7rem;
}
.lines {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    margin: auto;
    width: 90vw;
    z-index: -1;
}
.lines .line:nth-child(1) {
    margin-left: -25%;
}
.lines .line {
    position: absolute;
    width: 1px;
    height: 100%;
    top: 0;
    left: 50%;
    background: rgba(255, 255, 255, 0.1);
    overflow: hidden;
}
.lines .line:nth-child(1)::after {
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
}
.lines .line::after {
    content: "";
    display: block;
    position: absolute;
    height: 15vh;
    width: 100%;
    top: -50%;
    left: 0;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #ffffff 75%, #ffffff 100%);
    -webkit-animation: run 7s 0s infinite;
    animation: run 7s 0s infinite;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
    animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
}
.lines .line:nth-child(3) {
    margin-left: 25%;
}
.lines .line:nth-child(3)::after {
    -webkit-animation-delay: 2.5s;
    animation-delay: 2.5s;
}
.upgrade-content{
    padding: 6rem;
}
.upgrade-heading{
    display: flex;
    margin-bottom: 3rem;
}
.upgrade-heading .fa{
    font-size: 1.8rem;
    color: #fff;
    background-color: #282560;
    padding: 1rem;
    border-radius: 10px;
}
.upgrade-heading .upgrade-title{
    margin-left: 1rem;
    margin-top: 0.6rem;
    color: #282560;
}
.upgrade-para{
    text-align: justify;
}
@media only screen and (max-width:1025px){
    .upgrade-links{
        margin: 0;
        text-align: center;
    }
    .upgrade-links ul{
        justify-content: center;
        margin-left: -2rem;

    }
    .upgrade-content{
        padding: 3rem 1rem;
    }
}
.baggage-container{
    position: relative;
}
.baggage-banner-area{
    margin-top: -6.5rem;
    position: relative;
    z-index: 1;
    padding-top: 210px;
    padding-bottom: 140px;
    background-color: #000000;
    background-position: center right !important;
    background-size:cover !important;
    background-repeat: no-repeat !important;
} 
.baggage-banner-area::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: #000000;
    opacity: .60;
}
.baggage-links{
    /* margin: -2rem 0 0 10rem; */
}
.baggage-links ul{
    display: flex;
    margin-left: -2rem;
}
.baggage-links ul li{
    list-style-type: none;
    font-weight: 600;
    color: #fff;
    margin-right: 0.5rem;
}
.home-link{
    text-decoration: none;
    color: tomato;
}
.home-link:hover{
    color: tomato;
    cursor: pointer;
}
.baggage-h2{
    color: #fff;
    font-weight: 600;
    font-size: 2.7rem;
}
.lines {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    margin: auto;
    width: 90vw;
    z-index: -1;
}
.lines .line:nth-child(1) {
    margin-left: -25%;
}
.lines .line {
    position: absolute;
    width: 1px;
    height: 100%;
    top: 0;
    left: 50%;
    background: rgba(255, 255, 255, 0.1);
    overflow: hidden;
}
.lines .line:nth-child(1)::after {
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
}
.lines .line::after {
    content: "";
    display: block;
    position: absolute;
    height: 15vh;
    width: 100%;
    top: -50%;
    left: 0;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #ffffff 75%, #ffffff 100%);
    -webkit-animation: run 7s 0s infinite;
    animation: run 7s 0s infinite;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
    animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
}
.lines .line:nth-child(3) {
    margin-left: 25%;
}
.lines .line:nth-child(3)::after {
    -webkit-animation-delay: 2.5s;
    animation-delay: 2.5s;
}
.baggage-content{
    padding: 6rem;
}
.baggage-heading{
    display: flex;
    margin-bottom: 3rem;
}
.baggage-heading .fa{
    font-size: 1.8rem;
    color: #fff;
    background-color: #282560;
    padding: 1rem;
    border-radius: 10px;
}
.baggage-heading .baggage-title{
    margin-left: 1rem;
    margin-top: 0.6rem;
    color: #282560;
}
.baggage-para{
    text-align: justify;
}

@media only screen and (max-width:1025px){
    .baggage-links{
        margin: 0;
        text-align: center;
    }
    .baggage-links ul{
        justify-content: center;
        margin-left: -2rem;

    }
    .baggage-content{
        padding: 3rem 1rem;
    }
}
.lounge-container{
    position: relative;
}
.lounge-banner-area{
    margin-top: -6.5rem;
    position: relative;
    z-index: 1;
    padding-top: 210px;
    padding-bottom: 140px;
    background-color: #000000;
    background-position: center right !important;
    background-size:cover !important;
    background-repeat: no-repeat !important;
} 
.lounge-banner-area::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: #000000;
    opacity: .60;
}
.lounge-links{
    /* margin: -2rem 0 0 10rem; */
}
.lounge-links ul{
    display: flex;
    margin-left: -2rem;
}
.lounge-links ul li{
    list-style-type: none;
    font-weight: 600;
    color: #fff;
    margin-right: 0.5rem;
}
.home-link{
    text-decoration: none;
    color: tomato;
}
.home-link:hover{
    color: tomato;
    cursor: pointer;
}
.lounge-h2{
    color: #fff;
    font-weight: 600;
    font-size: 2.7rem;
}
.lines {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    margin: auto;
    width: 90vw;
    z-index: -1;
}
.lines .line:nth-child(1) {
    margin-left: -25%;
}
.lines .line {
    position: absolute;
    width: 1px;
    height: 100%;
    top: 0;
    left: 50%;
    background: rgba(255, 255, 255, 0.1);
    overflow: hidden;
}
.lines .line:nth-child(1)::after {
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
}
.lines .line::after {
    content: "";
    display: block;
    position: absolute;
    height: 15vh;
    width: 100%;
    top: -50%;
    left: 0;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #ffffff 75%, #ffffff 100%);
    -webkit-animation: run 7s 0s infinite;
    animation: run 7s 0s infinite;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
    animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
}
.lines .line:nth-child(3) {
    margin-left: 25%;
}
.lines .line:nth-child(3)::after {
    -webkit-animation-delay: 2.5s;
    animation-delay: 2.5s;
}
.lounge-content{
    padding: 6rem;
}
.lounge-heading{
    display: flex;
    margin-bottom: 3rem;
}
.lounge-heading .fa{
    font-size: 1.8rem;
    color: #fff;
    background-color: #282560;
    padding: 1rem;
    border-radius: 10px;
}
.lounge-heading .lounge-title{
    margin-left: 1rem;
    margin-top: 0.6rem;
    color: #282560;
}
.lounge-para{
    text-align: justify;
}
@media only screen and (max-width:1025px){
    .lounge-links{
        margin: 0;
        text-align: center;
    }
    .lounge-links ul{
        justify-content: center;
        margin-left: -2rem;

    }
    .lounge-content{
        padding: 3rem 1rem;
    }
}
.emptyseat-container{
    position: relative;
}
.emptyseat-banner-area{
    margin-top: -6.5rem;
    position: relative;
    z-index: 1;
    padding-top: 210px;
    padding-bottom: 140px;
    background-color: #000000;
    background-position: center right !important;
    background-size:cover !important;
    background-repeat: no-repeat !important;
} 
.emptyseat-banner-area::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: #000000;
    opacity: .60;
}
.emptyseat-links{
    /* margin: -2rem 0 0 10rem; */
}
.emptyseat-links ul{
    display: flex;
    margin-left: -2rem;
}
.emptyseat-links ul li{
    list-style-type: none;
    font-weight: 600;
    color: #fff;
    margin-right: 0.5rem;
}
.home-link{
    text-decoration: none;
    color: tomato;
}
.home-link:hover{
    color: tomato;
    cursor: pointer;
}
.emptyseat-h2{
    color: #fff;
    font-weight: 600;
    font-size: 2.7rem;
}
.lines {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    margin: auto;
    width: 90vw;
    z-index: -1;
}
.lines .line:nth-child(1) {
    margin-left: -25%;
}
.lines .line {
    position: absolute;
    width: 1px;
    height: 100%;
    top: 0;
    left: 50%;
    background: rgba(255, 255, 255, 0.1);
    overflow: hidden;
}
.lines .line:nth-child(1)::after {
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
}
.lines .line::after {
    content: "";
    display: block;
    position: absolute;
    height: 15vh;
    width: 100%;
    top: -50%;
    left: 0;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #ffffff 75%, #ffffff 100%);
    -webkit-animation: run 7s 0s infinite;
    animation: run 7s 0s infinite;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
    animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
}
.lines .line:nth-child(3) {
    margin-left: 25%;
}
.lines .line:nth-child(3)::after {
    -webkit-animation-delay: 2.5s;
    animation-delay: 2.5s;
}
.emptyseat-content{
    padding: 6rem;
}
.emptyseat-heading{
    display: flex;
    margin-bottom: 3rem;
}
.emptyseat-heading .fa{
    font-size: 1.8rem;
    color: #fff;
    background-color: #282560;
    padding: 1rem;
    border-radius: 10px;
}
.emptyseat-heading .emptyseat-title{
    margin-left: 1rem;
    margin-top: 0.6rem;
    color: #282560;
}
.emptyseat-para{
    text-align: justify;
}
@media only screen and (max-width:1025px){
    .emptyseat-links{
        margin: 0;
        text-align: center;
    }
    .emptyseat-links ul{
        justify-content: center;
        margin-left: -2rem;

    }
    .emptyseat-content{
        padding: 3rem 1rem;
    }
}
.preferredseat-container{
    position: relative;
}
.preferredseat-banner-area{
    margin-top: -6.5rem;
    position: relative;
    z-index: 1;
    padding-top: 210px;
    padding-bottom: 140px;
    background-color: #000000;
    background-position: center right !important;
    background-size:cover !important;
    background-repeat: no-repeat !important;
} 
.preferredseat-banner-area::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: #000000;
    opacity: .60;
}
.preferredseat-links{
    /* margin: -2rem 0 0 10rem; */
}
.preferredseat-links ul{
    display: flex;
    margin-left: -2rem;
}
.preferredseat-links ul li{
    list-style-type: none;
    font-weight: 600;
    color: #fff;
    margin-right: 0.5rem;
}
.home-link{
    text-decoration: none;
    color: tomato;
}
.home-link:hover{
    color: tomato;
    cursor: pointer;
}
.preferredseat-h2{
    color: #fff;
    font-weight: 600;
    font-size: 2.7rem;
}
.lines {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    margin: auto;
    width: 90vw;
    z-index: -1;
}
.lines .line:nth-child(1) {
    margin-left: -25%;
}
.lines .line {
    position: absolute;
    width: 1px;
    height: 100%;
    top: 0;
    left: 50%;
    background: rgba(255, 255, 255, 0.1);
    overflow: hidden;
}
.lines .line:nth-child(1)::after {
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
}
.lines .line::after {
    content: "";
    display: block;
    position: absolute;
    height: 15vh;
    width: 100%;
    top: -50%;
    left: 0;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #ffffff 75%, #ffffff 100%);
    -webkit-animation: run 7s 0s infinite;
    animation: run 7s 0s infinite;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
    animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
}
.lines .line:nth-child(3) {
    margin-left: 25%;
}
.lines .line:nth-child(3)::after {
    -webkit-animation-delay: 2.5s;
    animation-delay: 2.5s;
}
.preferredseat-content{
    padding: 6rem;
}
.preferredseat-heading{
    display: flex;
    margin-bottom: 3rem;
}
.preferredseat-heading .fa{
    font-size: 1.8rem;
    color: #fff;
    background-color: #282560;
    padding: 1rem;
    border-radius: 10px;
}
.preferredseat-heading .preferredseat-title{
    margin-left: 1rem;
    margin-top: 0.6rem;
    color: #282560;
}
.preferredseat-para{
    text-align: justify;
}
@media only screen and (max-width:1025px){
    .preferredseat-links{
        margin: 0;
        text-align: center;
    }
    .preferredseat-links ul{
        justify-content: center;
        margin-left: -2rem;

    }
    .preferredseat-content{
        padding: 3rem 1rem;
    }
}
.ancillary-container{
    position: relative;
}
.ancillary-banner-area{
    margin-top: -6.5rem;
    position: relative;
    z-index: 1;
    padding-top: 210px;
    padding-bottom: 140px;
    background-color: #000000;
    background-position: center right !important;
    background-size:cover !important;
    background-repeat: no-repeat !important;
} 
.ancillary-banner-area::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: #000000;
    opacity: .60;
}
.ancillary-links{
    /* margin: -2rem 0 0 10rem; */
}
.ancillary-links ul{
    display: flex;
    margin-left: -2rem;
}
.ancillary-links ul li{
    list-style-type: none;
    font-weight: 600;
    color: #fff;
    margin-right: 0.5rem;
}
.home-link{
    text-decoration: none;
    color: tomato;
}
.home-link:hover{
    color: tomato;
    cursor: pointer;
}
.ancillary-h2{
    color: #fff;
    font-weight: 600;
    font-size: 2.7rem;
}
.lines {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    margin: auto;
    width: 90vw;
    z-index: -1;
}
.lines .line:nth-child(1) {
    margin-left: -25%;
}
.lines .line {
    position: absolute;
    width: 1px;
    height: 100%;
    top: 0;
    left: 50%;
    background: rgba(255, 255, 255, 0.1);
    overflow: hidden;
}
.lines .line:nth-child(1)::after {
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
}
.lines .line::after {
    content: "";
    display: block;
    position: absolute;
    height: 15vh;
    width: 100%;
    top: -50%;
    left: 0;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #ffffff 75%, #ffffff 100%);
    -webkit-animation: run 7s 0s infinite;
    animation: run 7s 0s infinite;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
    animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
}
.lines .line:nth-child(3) {
    margin-left: 25%;
}
.lines .line:nth-child(3)::after {
    -webkit-animation-delay: 2.5s;
    animation-delay: 2.5s;
}
.ancillary-content{
    padding: 6rem;
}
.ancillary-heading{
    display: flex;
    margin-bottom: 3rem;
}
.ancillary-heading .fa-plus{
    font-size: 1.8rem;
    color: #fff;
    background-color: #282560;
    padding: 1rem;
    border-radius: 10px;
}
.ancillary-heading .ancillary-title{
    margin-left: 1rem;
    margin-top: 0.6rem;
    color: #282560;
}
.ancillary-para{
    text-align: justify;
}
@media only screen and (max-width:1025px){
    .ancillary-links{
        margin: 0;
        text-align: center;
    }
    .ancillary-links ul{
        justify-content: center;
        margin-left: -2rem;

    }
    .ancillary-content{
        padding: 3rem 1rem;
    }
}
.aviation-container{
    position: relative;
}
.aviation-banner-area{
    margin-top: -6.5rem;
    position: relative;
    z-index: 1;
    padding-top: 210px;
    padding-bottom: 140px;
    background-color: #000000;
    background-position: center right !important;
    background-size:cover !important;
    background-repeat: no-repeat !important;
} 
.aviation-banner-area::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: #000000;
    opacity: .60;
}
.aviation-links{
    /* margin: -2rem 0 0 10rem; */
}
.aviation-links ul{
    display: flex;
    margin-left: -2rem;
}
.aviation-links ul li{
    list-style-type: none;
    font-weight: 600;
    color: #fff;
    margin-right: 0.5rem;
}
.home-link{
    text-decoration: none;
    color: tomato;
}
.home-link:hover{
    color: tomato;
    cursor: pointer;
}
.aviation-h2{
    color: #fff;
    font-weight: 600;
    font-size: 2.7rem;
}
.lines {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    margin: auto;
    width: 90vw;
    z-index: -1;
}
.lines .line:nth-child(1) {
    margin-left: -25%;
}
.lines .line {
    position: absolute;
    width: 1px;
    height: 100%;
    top: 0;
    left: 50%;
    background: rgba(255, 255, 255, 0.1);
    overflow: hidden;
}
.lines .line:nth-child(1)::after {
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
}
.lines .line::after {
    content: "";
    display: block;
    position: absolute;
    height: 15vh;
    width: 100%;
    top: -50%;
    left: 0;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #ffffff 75%, #ffffff 100%);
    -webkit-animation: run 7s 0s infinite;
    animation: run 7s 0s infinite;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
    animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
}
.lines .line:nth-child(3) {
    margin-left: 25%;
}
.lines .line:nth-child(3)::after {
    -webkit-animation-delay: 2.5s;
    animation-delay: 2.5s;
}
.aviation-content{
    padding: 6rem;
}
.aviation-heading{
    display: flex;
    margin-bottom: 3rem;
}
.aviation-heading .fa{
    font-size: 1.8rem;
    color: #fff;
    background-color: #282560;
    padding: 1rem;
    border-radius: 10px;
}
.aviation-heading .aviation-title{
    margin-left: 1rem;
    margin-top: 0.6rem;
    color: #282560;
}
.aviation-para{
    text-align: justify;
}
.chess-img{
    width: 600px;
    height: 400px;
    margin: 1rem 0 ;
}
@media only screen and (max-width:1025px){
    .aviation-links{
        margin: 0;
        text-align: center;
    }
    .aviation-links ul{
        justify-content: center;
        margin-left: -2rem;

    }
    .aviation-content{
        padding: 3rem 1rem;
    }
    .chess-img{
        width:100%;
        height: auto;
    }
}
.news-container{
    position: relative;
}
.news-banner-area{
    margin-top: -6.5rem;
    position: relative;
    z-index: 1;
    padding-top: 210px;
    padding-bottom: 140px;
    background-color: #000000;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat; 
} 
.news-banner-area::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: #000000;
    opacity: .60;
}
.news-links{
    margin: 0;
}
.news-links ul{
    display: flex;
    margin-left: -2rem;
}
.news-links ul li{
    list-style-type: none;
    font-weight: 600;
    color: #fff;
    margin-right: 0.5rem;
}
.home-link{
    text-decoration: none;
    color: tomato;
}
.home-link:hover{
    color: tomato;
    cursor: pointer;
}
.news-h2{
    color: #fff;
    font-weight: 600;
    font-size: 2.7rem;
}
.lines {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    margin: auto;
    width: 90vw;
    z-index: -1;
}
.lines .line:nth-child(1) {
    margin-left: -25%;
}
.lines .line {
    position: absolute;
    width: 1px;
    height: 100%;
    top: 0;
    left: 50%;
    background: rgba(255, 255, 255, 0.1);
    overflow: hidden;
}
.lines .line:nth-child(1)::after {
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
}
.lines .line::after {
    content: "";
    display: block;
    position: absolute;
    height: 15vh;
    width: 100%;
    top: -50%;
    left: 0;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #ffffff 75%, #ffffff 100%);
    -webkit-animation: run 7s 0s infinite;
    animation: run 7s 0s infinite;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
    animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
}
.lines .line:nth-child(3) {
    margin-left: 25%;
}
.lines .line:nth-child(3)::after {
    -webkit-animation-delay: 2.5s;
    animation-delay: 2.5s;
}
.news-content-container{
    display: flex;
    margin-top: 6rem;
}
.news-left-content{
    width: 100%;
    padding-left: 0rem;
    padding-right: 6rem;
}
.news-right-content{
    width: 40%;
    padding-left: 2rem;
    padding-right: 0rem;
}
.malaysia-para{
    width: 100%;
}
/* .article-container{
    text-align: left;
    margin-top: 3rem;
} */
/* .article-container h1{
    text-align: center;
}
#head-para{
    text-align: center;
}
.article-container p{
    text-align: left;
}
.article-container h1{
    font-size: 3rem;
} */
/* .article-content{
    display: flex;
    padding: 1rem 5rem;
    justify-content: center;
} */
.news-img{
    width: 500px;
    height: 320px;
    transition: 0.5s;
}
.news-img:hover{
    opacity: 0.7;
    -webkit-transform: scale(1.02);
            transform: scale(1.02);
    overflow: hidden;
}
.content-news-container{
    padding: 1rem 5.5rem 2rem 2rem;
}
.news-para{
    text-align: left;
}
.article-link{
    text-decoration: none;
    color: black;
    font-size: 1.3rem;
    font-weight: 600;
    margin-bottom: 1rem;
    text-align: justify;
}
.article-link:hover{
    color: black;
}
.read-btn{
    text-align: left;
    position: relative;
    text-decoration: none;
    color:#333333;
}
.read-btn::before {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 1px;
    bottom: -2px;
    left: 0;
    background-color: #000;
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    transition: -webkit-transform 0.3s ease;
    transition: transform 0.3s ease;
    transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  }
  .read-btn:hover::before {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }
.read-btn:hover{
    color: #333;
}
/* .aeroplane-container,
.globe-container,
.pricing-container{
    margin: 0 1.2rem;
} */
.search-container{
    margin-top: 1rem;
    margin-bottom: 3rem;
}
.search-input{
    padding: 0.7rem 1.4rem;
    border: none;
    outline: none;
}
.search-input:focus{
    border: 1px solid black;
}
.fa-search{
    padding: 1rem;
    background-color: #1b2f5b;
    color: #fff;
    transition: 0.5s;
}
.fa-search:hover{
    background-color: tomato;
}
.widget-title{
    border-bottom: 1px solid #d3dbe2;
    padding-bottom: 10px;
    margin-bottom: 20px;
    text-transform: capitalize;
    position: relative;
    font-weight: 500;
    font-size: 20px;
}
.widget-title::before{
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 50px;
    height: 1px;
    background-color: #1b2f5b;
}
.annoucement-link{
    text-decoration: none;
    color: black;
    opacity: 0.8;
}
.annoucement-link:hover{
    color: black;
}
.recent-posts{
    margin-top: 6rem;
}
.news-link{
    text-decoration: none;
    color: #57647c;
    font-weight: 600;
    font-size: 1rem;
    transition: 0.5s;
    display: block;
    margin: 1rem 0;
    position: relative;
}
.news-link::before{
    content: '';
    position: absolute;
    height: 7px;
    width: 7px;
    left: -15px;
    top: 8px;
    background-color: #1b2f5b;
}
.news-link:hover{
    color: black;
    opacity: 1;
}
.recent-comments{
    margin-top: 6rem;
}
.categories{
    margin-top: 6rem;
}
.malaysia-para{
    color: #57647c;
}
strong{
    color: #000;
}
.news-left-content a{
    color: #57647c;
}
.news-left-content a:hover{
    text-decoration: none;
    color: #57647c;
}
.comment-form label{
    display: block;
    color: #333333;
    font-weight: 600;
    font-family: "Dosis", sans-serif;
}
.name-email{
    display: flex;
}
.comment-form input,textarea{
    padding:1rem ;
    border: none;
    outline: none;
    margin-right: 5rem;
}
.checkbox-container{
    display: inline;
}
.comment-form-container{
    margin: 3rem 0;
}
.comment-form-container p strong{
    font-family: "Dosis", sans-serif;
}
.comment-btn{
    background-color: #1b2f5b;
    color: #fff;
    margin-top: 2rem;
    margin-bottom: 3rem;
    font-weight: 600;
    border-radius: 5px;
    padding: 15px 25px;
    transition: 0.5s;
}
.comment-btn:hover{
    background-color: tomato;
}
@media only screen and (max-width:1025px){
    .comment-form input,textarea{
        padding:1rem ;
        margin-right: 0rem;
    }
    .comment-section textarea{
        margin: 0;
    }
}
.news-container{
    position: relative;
}

.news-banner-area{
    /*margin-top: -10rem !important;*/
    /* margin-left: -2rem !important; */
    position: relative;
    /*left: -1.275rem;*/
    top: 0;
    z-index: -20;
    padding-top: 210px;
    padding-bottom: 140px;
    background-color: #000000;
    background-position: center center !important;
    background-size: cover !important;
    background-repeat: no-repeat !important; 
} 
.news-banner-area::before {
    content: '';
    position: absolute;
    left: 0rem !important;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: #000000;
    background-position: center center !important;
    background-size: cover !important;
    background-repeat: no-repeat !important; 
    opacity: .60;
}
.news-links{
    margin:0;
}
.news-links ul{
    display: flex;
    margin-left: -2rem;
}
.news-links ul li{
    list-style-type: none;
    font-weight: 600;
    color: #fff;
    margin-right: 0.5rem;
}
.home-link{
    text-decoration: none;
    color: tomato;
}
.home-link:hover{
    color: tomato;
    cursor: pointer;
}
.news-h2{
    color: #fff;
    font-weight: 600;
    font-size: 2.7rem;
}
.lines {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    margin: auto;
    width: 90vw;
    z-index: -1;
}
.lines .line:nth-child(1) {
    margin-left: -25%;
}
.lines .line {
    position: absolute;
    width: 1px;
    height: 100%;
    top: 0;
    left: 50%;
    background: rgba(255, 255, 255, 0.1);
    overflow: hidden;
}
.lines .line:nth-child(1)::after {
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
}
.lines .line::after {
    content: "";
    display: block;
    position: absolute;
    height: 15vh;
    width: 100%;
    top: -50%;
    left: 0;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #ffffff 75%, #ffffff 100%);
    -webkit-animation: run 7s 0s infinite;
    animation: run 7s 0s infinite;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
    animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
}
.lines .line:nth-child(3) {
    margin-left: 25%;
}
.lines .line:nth-child(3)::after {
    -webkit-animation-delay: 2.5s;
    animation-delay: 2.5s;
}
.img-pricing{
    width: 100%;
    height: auto;
    margin-bottom: 2.5rem;
}
@media only screen and (max-width:1025px){
    .img-pricing{
        width: 100%;
        height: auto;
    }
    textarea{
        height: auto;
    }
    .news-banner-area{
        left: 0!important;
    }
}
.news-container{
    position: relative;
}
.news-banner-area{
    margin-top: -6.5rem;
    position: relative;
    z-index: 1;
    padding-top: 210px;
    padding-bottom: 140px;
    background-color: #000000;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat; 
} 
.news-banner-area::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: #000000;
    opacity: .60;
}
.news-links{
    margin:0;
}
.news-links ul{
    display: flex;
    margin-left: -2rem;
}
.news-links ul li{
    list-style-type: none;
    font-weight: 600;
    color: #fff;
    margin-right: 0.5rem;
}
.home-link{
    text-decoration: none;
    color: tomato;
}
.home-link:hover{
    color: tomato;
    cursor: pointer;
}
.news-h2{
    color: #fff;
    font-weight: 600;
    font-size: 2.7rem;
}
.lines {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    margin: auto;
    width: 90vw;
    z-index: -1;
}
.lines .line:nth-child(1) {
    margin-left: -25%;
}
.lines .line {
    position: absolute;
    width: 1px;
    height: 100%;
    top: 0;
    left: 50%;
    background: rgba(255, 255, 255, 0.1);
    overflow: hidden;
}
.lines .line:nth-child(1)::after {
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
}
.lines .line::after {
    content: "";
    display: block;
    position: absolute;
    height: 15vh;
    width: 100%;
    top: -50%;
    left: 0;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #ffffff 75%, #ffffff 100%);
    -webkit-animation: run 7s 0s infinite;
    animation: run 7s 0s infinite;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
    animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
}
.lines .line:nth-child(3) {
    margin-left: 25%;
}
.lines .line:nth-child(3)::after {
    -webkit-animation-delay: 2.5s;
    animation-delay: 2.5s;
}
.img-economics{
    width: 600px;
    height: 400px;
    margin-bottom: 2.5rem;
}
@media only screen and (max-width:1025px){
    .img-economics{
        width: 100%;
        height: auto;
    }
}
.news-container{
    position: relative;
}
.news-banner-area{
    margin-top: -6.5rem;
    position: relative;
    z-index: 1;
    padding-top: 210px;
    padding-bottom: 140px;
    background-color: #000000;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat; 
} 
.news-banner-area::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: #000000;
    opacity: .60;
}
.news-links{
    margin: 0;
}
.news-links ul{
    display: flex;
    margin-left: -2rem;
}
.news-links ul li{
    list-style-type: none;
    font-weight: 600;
    color: #fff;
    margin-right: 0.5rem;
}
.home-link{
    text-decoration: none;
    color: tomato;
}
.home-link:hover{
    color: tomato;
    cursor: pointer;
}
.news-h2{
    color: #fff;
    font-weight: 600;
    font-size: 2.7rem;
}
.news-links p{
    color: #fff;
}
.lines {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    margin: auto;
    width: 90vw;
    z-index: -1;
}
.lines .line:nth-child(1) {
    margin-left: -25%;
}
.lines .line {
    position: absolute;
    width: 1px;
    height: 100%;
    top: 0;
    left: 50%;
    background: rgba(255, 255, 255, 0.1);
    overflow: hidden;
}
.lines .line:nth-child(1)::after {
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
}
.lines .line::after {
    content: "";
    display: block;
    position: absolute;
    height: 15vh;
    width: 100%;
    top: -50%;
    left: 0;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #ffffff 75%, #ffffff 100%);
    -webkit-animation: run 7s 0s infinite;
    animation: run 7s 0s infinite;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
    animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
}
.lines .line:nth-child(3) {
    margin-left: 25%;
}
.lines .line:nth-child(3)::after {
    -webkit-animation-delay: 2.5s;
    animation-delay: 2.5s;
}
.img-crisis{
    width: 100%;
    height: 400px;
    margin-bottom: 2.5rem;
}
.pricing-para{
    text-align: justify;
}

@media only screen and (max-width:1025px){
    .news-links{
        padding: 1rem;
    }

    .news-links h2{
        font-size: 1.5rem;
    }
    .home-link{
        text-align: center;
    }
    .img-crisis{
        width: 100%;
        height: auto;
    }
    .news-left-content{
        text-align: justify;
        padding: 1rem !important;
    }
    .name-email{
        display: block;
    }
    .comment-btn{
        padding: 0.8rem !important;
        font-size: 0.8rem;

    }
    .references-container{
        position: relative;
        max-width: 100%;
        padding: 0rem;
        text-align: left;
    }
} 

.preloader {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  text-align: center;
  z-index: 9999999;
  transition: .9s;
}

.preloader .loader {
  position: absolute;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: inline-block;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 45%;
  -webkit-transform: translateY(-45%);
  transform: translateY(-45%);
  transition: 0.5s;
}

.preloader .loader .loader-outter {
  position: absolute;
  border: 4px solid #ffffff;
  border-left-color: transparent;
  border-bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  -webkit-animation: loader-outter 1s cubic-bezier(0.42, 0.61, 0.58, 0.41) infinite;
  animation: loader-outter 1s cubic-bezier(0.42, 0.61, 0.58, 0.41) infinite;
}

.preloader .loader .loader-inner {
  position: absolute;
  border: 4px solid #ffffff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  left: calc(50% - 20px);
  top: calc(50% - 20px);
  border-right: 0;
  border-top-color: transparent;
  -webkit-animation: loader-inner 1s cubic-bezier(0.42, 0.61, 0.58, 0.41) infinite;
  animation: loader-inner 1s cubic-bezier(0.42, 0.61, 0.58, 0.41) infinite;
}

.preloader::before, .preloader::after{
  background-color: #1b2f5b;
}
.preloader::before, .preloader::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 60%;
  z-index: -1;
  transition: .9s;
}
.preloader::after {
  top: auto;
  bottom: 0;
}

.preloader.preloader-deactivate {
  visibility:hidden;
}

.preloader.preloader-deactivate::after, .preloader.preloader-deactivate::before {
  height: 0;
}

.preloader.preloader-deactivate .loader {
  opacity: 0;
  visibility: hidden;
}

.preloader p {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
}

@-webkit-keyframes loader-outter {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes loader-outter {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@-webkit-keyframes loader-inner {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
  }
}

@keyframes loader-inner {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
  }
}
