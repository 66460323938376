.solution{
    margin-top: 10rem;
    margin-bottom: 3rem;
}

.solution-heading{
    width: 50%;
    text-align: center;
    margin: 0 auto;
    font-size: 2.5rem;
    font-weight: 600;
}
.solution-container{
    margin-top: 3rem;
    display: flex;
}
.list-container{
    width: 100%;
    margin-right: 10rem;
    color: #fff;
    text-align: left;
    align-items: center;
}
.card-container{
    width: 100%;
}
.list-container .prediction{
    width: 100%;
    margin: 1rem auto;
    background-color: #fff;
    color: #1b2f5b;
    padding: 1rem 0 ;
    border-radius: 10px;
    align-self: center;
    cursor: pointer;
    transition: 0.5s;
}
.list-container .prediction.active{
   color: #fff;
   background-color: #1b2f5b;
}
.list-container .prediction.active .fa{
    background-color: #fff;
    color: #1b2f5b;
}
.list-container .prediction:hover{
    color: #fff;
   background-color: #1b2f5b;
}
.list-container .prediction .fa{
    font-size: 2rem;
    margin: 0 1.7rem;
    padding: 0.8rem;
    border-radius: 10px;
    background-color: #fff;
    color: #1b2f5b;
}
.list-container .prediction:hover .fa{
    background-color: #fff;
    color: #1b2f5b;
}
.card-container p{
    width: 100%;
    text-align: justify;
    margin-top: 1rem;
}
.readmore-button{
    background-color: #1b2f5b;
    color: #fff;
    padding: 0.6rem 2rem;
    border-radius: 2rem;
    text-decoration: none;
    margin-top: 2rem;
    transition: 0.6s ease;
}
.readmore-button:hover{
    background-color: tomato;
    color: #fff;
    font-weight: 600;
}
#predict{
    display: block;
}
#analysis{
    display: none;
}
#reporting{
    display: none;
}

@media only screen and (max-width:1025px){
    .solution-heading{
        width: 100%;
        font-size: 1.7rem;
        font-weight: 500;
    }
    .solution{
        margin-top: 3rem;
        margin-bottom: 3rem;
    }
    .solution-container{
       flex-direction: column;
       padding: 0 1rem ;
    }
    .list-container{
        width: 100%;
        margin: 1rem auto;
    }
    .list-container .prediction{
        width: 100%;
        margin-bottom: 1rem;
        font-size: 1rem;
    }
    .prediction .fa{
        font-size: 1rem;
        margin: 0 1rem
    }
    .card-container{
        width: 100%;
    }
    .card-container img{
        margin: 1rem auto;
        width: 100%;
        height: auto;
    }
    .card-container p{
        width: 100%;
        margin: 1rem 0 2rem 0;
        padding: 0 1rem;
        font-size: 0.9rem;
    }
    .readmore-button{
        padding: 0.7rem 1.6rem;
        font-size: 0.8rem;
        margin: 3rem 1rem;
    }
}