.num-container{
    display: flex;
    justify-content: center;
    margin-top: 2rem;
}
.number-container{
    margin: 0.4rem 2rem;
    text-align: center;
}
span{
    color: tomato;
    font-weight: 600;
    line-height: 1.3rem;
    font-size: 1.3rem;
}

@media only screen and (max-width:1025px){
    .num-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 2rem;
    }
}