.do-container{
    display: flex;
}
.img-container{
    width: 50%;
    position: relative;
    top: 4rem;
    left: 0rem;
}
.text-container{
    width: 50%;
    padding: 5rem 2.5rem;
    margin-top: 4.5rem;
    margin-left: 5rem;
    padding-right: 0;
}
.text-container p{
    margin-bottom: 1rem;
    text-align: justify;
}
.image-container-1{
   position: relative;
}
.image-container-2{
    position: absolute;
    top: 6.1rem;
    left: 11.5rem;
}
.discover-btn{
    background-color: #1b2f5b;
    padding: 0.6rem 2.5rem;
    border-radius: 1.5rem;
    text-decoration: none;
    color: #fff;
    transition: 0.5s ease;
    font-weight: 600;
}
.discover-btn:hover{
    background-color: tomato;
    color: #fff;
}
@media only screen and (max-width:1230px) {
    .do-container{
        flex-direction: column;
    }
    .img-container{
        width: 100%;
        margin-bottom: 5rem ;
    }
    .text-container{
        width: 100%;
        margin-top: 5rem;
    }

}

@media only screen and (max-width:1025px){
    .image-container-2{
        display: none;
    }
    .img-container{
        width: 100%;
        top: 0rem;
        left: 0rem;
        margin: 1rem auto;
    }
    .image-container-1{
        padding: 1rem;
    }
    .about-img{
        width:100%;
        height: auto;
     }
     .do-container{
        display: flex;
        flex-direction: column-reverse;
    }
    .text-container{
        width: 100%;
        padding: 1rem;
        margin: 1rem 0;
    }
    .discover-btn{
        padding: 0.6rem 2rem;
        font-weight: 500;
        font-size: 0.8rem;
    }
}

