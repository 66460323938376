.slider{
    margin-top: -5rem;
    z-index: 0;
    height: auto;
    
    width: 100%;
    margin-top: -96px;
}

.main-banner-content{
    text-align: left;
    position: absolute;
    top: 50%;
    left:0;
    transform: translate(10%,-250%);
    font-family: 'Montserrat', sans-serif;
    line-height: 3rem;
}
.reach-out-btn{
    background-color:#1b2f5b;
    padding: 0.6rem 2rem;
    border-radius: 20px;
   color: #fff;
   text-decoration: none;
   z-index: 3000;
}
.slider .carousel-item::before{
    content: unset; 
}
.reach-out-btn:hover{
    background-color: tomato !important;
    color: #fff !important;
}
.heading{
    font-size: 4rem;
    letter-spacing: 1.5;
}
@media only screen and (max-width:1500px) {
    .main-banner-content{
        top: 250%;
        left:0;
        transform: translate(10%,-250%);
    }
}
@media only screen and (max-width:1150px) {
    .main-banner-content{
        top: 550%;
        left:0;
        transform: translate(10%,-250%);
    }
}
@media only screen and (max-width:1025px){
    .main-banner-content{
        /* top: -15rem;
        left: -1rem; */
        top: 30%;
        left:0;
        transform: translate(10%,-180%);
        padding: 0 0rem;
        line-height: 1.5rem;
    }
    .banner-img{
        height: 550px;
    }
    .heading{
        font-size: 2rem;
        letter-spacing: 1.1;
    }
    /* .button{
        font-size: 0.7rem;
        padding: 0.4rem 1rem;
    } */
    .main-banner-content p{
        font-size: 0.8rem;
        font-weight: 300;
    }
}