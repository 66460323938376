.reachout-container{
    width: 100%;
    height: auto;
    display: flex;
    position: relative;
    z-index: 1;
}
.reach-bg::before{
    background: url("./images/2.png");
}
.reachout-container::before{
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    width: 63%;
    height: 100%;
    z-index: 1;
    background-position: left top;
    background-size: cover;
    background-repeat: no-repeat;
}
.woman-container{
    position: relative;
    width: 100%;
    height: 500px;
    background-position: center left;
    background-size: auto;
    background-repeat: no-repeat;
}
.woman-container img{
    height: 600px;
    width: 70%;
    display: none;
    background-size: cover;
    background-repeat: no-repeat;
}
.reach-text{
    z-index: 3;
    position: absolute;
    top: 15rem;
    left: 60%;
}
.reach-text h1{
    color: #fff;
}
.reach-text p{
    color: #fff;
}
.reach-text h1{
    font-weight: 800;
    font-size: 2.7rem;
}
.reach-text h4{
    margin-top: -0.725rem;
    margin-bottom: 1.3rem;
    font-size: 1.7rem;
    font-weight: 600;
}
@media only screen and (max-width:1025px){
    .reach-bg::before{
        background: none;
    }
    .reachout-container{
        flex-direction: column;
    }
    .woman-container{
        height: auto;
        background-image: none;
    }
    .woman-container img{
        height: auto;
        width: 100%;
        display: block;
        background-size: cover;
        background-repeat: no-repeat;
    }
    .reach-text{
        background-color:#1b2f5b ;
        top: 0rem;
        left: 0rem;
        padding: 3rem;
        position: relative;
        text-align: center;
    }
    .reachout-container::before{
        display: none;
    }
    .reach-text h1{
        font-size: 1.5rem;
        font-weight: 700;
    }
    .reach-text p{
        font-size: 0.8rem;
    }
}